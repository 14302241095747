import { AxiosResponse } from "axios";
import { UPLOAD_TYPES } from "../models/common.model";
import { ENDPOINT_URL, post } from "../utils/https";
import { HttpResponseType } from "../models/https.model";

export const fileUpload = async (file: File, fileType: UPLOAD_TYPES, id?: string): Promise<AxiosResponse<HttpResponseType<string>>> => {
    const url =  'config/file-upload';
    const formData = new FormData();
    formData.append('file',file)
    formData.append('fileType', fileType)
    if(id) {
        formData.append('id', id)
    }
    return await post(url, formData, {
        "Content-Type": 'multipart/form-data'
    })
  }