import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IGenreOutput } from '../../models/genre.model'
import { fetchGenresList } from '../../views/pages/contest/genre-services'
import { ILanguageOutput } from '../../models/language.model'
import { fetchLanguagesList } from '../../views/pages/contest/language-services'

export interface ILanguagesState extends ILanguageOutput {}

export interface IGenresState extends IGenreOutput {}

interface IUserPreferencesState {
    languages: ILanguagesState[]
    genres: IGenresState[]
    selectedLanguages: number[]
    selectedGenres: IGenresState[]
}

const defaultState: IUserPreferencesState = {
    languages: [],
    genres: [],
    selectedGenres: [],
    selectedLanguages: []
}

export const UserPreferencesReducer = createSlice({
  name: 'UserPreferencesReducer',
  initialState: defaultState,
  reducers: {
    setSelectedGenres: (state: IUserPreferencesState, payload:PayloadAction<IGenresState[]> ) => {
      state.selectedGenres= payload.payload
    },
    setSelectedLanguages: (state: IUserPreferencesState, payload:PayloadAction<number[]> ) => {
        state.selectedLanguages= payload.payload
    }
  },
  extraReducers(builder) {
      builder.
        addCase(fetchGenresList.fulfilled, (state: IUserPreferencesState, action: PayloadAction<any>) => {
          state.genres = action.payload.data
        })
        .addCase(fetchLanguagesList.fulfilled, (state: IUserPreferencesState, action: PayloadAction<any>) => {
         state.languages = action.payload.data
        })
  },
})

export const { setSelectedGenres, setSelectedLanguages} = UserPreferencesReducer.actions

export default UserPreferencesReducer.reducer