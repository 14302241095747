import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Coverimage from '../../../images/about-cover-image.png'
import Ceo from '../../../images/ceo-founder.png'
import Violetcurve from '../../../images/curve-violet.png'
import Aboutgirl from '../../../images/about-girl.png'
import '../about-us/about-content.scss'
import '../landing/your-thought-guard.scss'
function RowColLayoutExample() {
    return (
        <>
            <Container className='main-container'>

                <Row className='rows-sl-web' xs={1} md={2}>
                    <Col className='rows-sl-web-col-one'>

                        <h1>Where Talented Writers Forge

                            Success in the Spotlight</h1>

                    </Col>

                    <Col className='rows-sl-web-col-two'>
                        <p>Uniting writers & production companies. Showcasing scripts in contests by industry leaders.
                            Your path to spotlight success begins here, where dreams become reality.</p>
                    </Col>

                    <Col className='rows-sl-web-col-three'>
                        <img src={Coverimage} style={{ width: '100%', height: '36rem' }} alt='cover-about' />
                    </Col>
                </Row>

                <hr style={{ height: '4px', marginTop: '5.563rem', background: 'linear-gradient(315deg, #0F73B1 -23.9%, #3C096C 35.06%, #3C096C 54.06%, #FF5400 166.94%)' }} />


                <Row className='container-sl-main' xs={1} md={2}>
                    <Col className='col-one' style={{ height: '51rem' }} >Together we are creating a world for tomorrow </Col>
                    <Col className='col-two' style={{ height: '51rem' }} >
                        <h5>Our Crew is always getting bigger, but we all work towards one goal: to make storyline success not only possible but inevitable for all everywhere.</h5> <br />
                        <p>At Storyline, we believe that our people are our greatest asset, and their passion and expertise are the driving force behind Storyline success. As we continue to grow and evolve, our team remains at the core of our success story, empowering us to envision a future where [Your App Name] continues to inspire, delight, and revolutionize the [industry or market] landscape. Together, we are more than a team; we are a family, committed to making a difference and writing the next chapter of Storyline's remarkable journey.</p>
                    </Col>
                    <br />
                    <Col className='col-three' style={{ height: '21rem' }}  >

                        <img src={Ceo} alt='ceo-founder' style={{ width: '7rem', height: '7rem' }} />
                        <Col>
                            <h4 className='ceo-name'>Phani Sammeta</h4>
                            <p className='ceo-name-tag'>CEO, Founder Storyline</p>

                        </Col>


                    </Col>
                    <Col className='col-four' style={{ height: '21rem' }} >
                        <h4 className='our-sl-web-goal'>“Our goal is to ignite change and shape the landscape of tomorrow through innovation, transforming lives and industries, one breakthrough at a time.”</h4>
                    </Col>
                </Row>

                <hr style={{ height: '4px', marginTop: '5.563rem', background: 'linear-gradient(315deg, #0F73B1 -23.9%, #3C096C 35.06%, #3C096C 54.06%, #FF5400 166.94%)' }} />



                <Row className='question-container' xs={1} md={2}>
                    <Col className='have' >
                        <h4 className='have-content-qstn'>Have a question ??
                            <br />
                            Our team is happy to assist you.</h4>

                        <p className='sl-web-ask'>Ask about Storyline products, pricing, implementation,
                            or anything  else. Our highly trained reps are standing by. Ready to help.</p>

                        <button className='contact-us-btn'>Contact us</button>

                        <hr className='have-a-horz-line' style={{ backgroundColor: 'white', width: '90%', height: '4px', marginRight: '20px', bottom: '175px', position: 'relative', left: '5%' }} />
                    </Col>


                    <Col className='picture-image' >


                        <div className='orange-color'

                        ></div>
                        <div className='violet-color-curve'>
                            <img className='image-curve' src={Violetcurve} />
                        </div>
                        <div>
                            <img className='about-girl' src={Aboutgirl} />
                        </div>

                    </Col>

                </Row>




            </Container>
            <div className='second-row-third-column'>


                <div className='main-landing-footer'>
                    <p className='copyright'> &copy; 2024 Storyline Inc </p>

                    <span className="anchor">

                        <a className='privacy' href="rep">  Terms</a>
                        <a className='privacy' > Accessibility</a>
                        <a className='privacy' href="rep">Privacy </a>

                    </span>

                </div>

            </div>

        </>


    );
}

export default RowColLayoutExample;