import { DropdownOption } from "../models/common.model"
import { ICountryOutput } from "../models/configurations.model"

export const MSG = 'MESSAGE'

export const ROLES = {
    SL_ADMIN: 'SL_ADMIN',
    SL_ACCOUNT_OWNER: 'SL_ACCOUNT_OWNER',
    SL_ACCOUNT_ADMIN: 'SL_ACCOUNT_ADMIN',
    SL_ACCOUNT_USER: 'SL_ACCOUNT_USER',
    SL_WRITER: 'SL_WRITER'
}

export const DEFAULT_COUNTRY: ICountryOutput = {
    "id": 101,
    "name": "India",
    "code": "IN",
    "status": "ACTIVE",
    "createdAt": new Date("2023-11-11T17:54:49.000Z"),
    "updatedAt": null
}

export const COUNTRY_USA: ICountryOutput = {
    "id": 231,
    "name": "USA",
    "code": "US",
    "status": "ACTIVE",
    "createdAt": new Date("2023-11-11T17:54:49.000Z"),
    "updatedAt": null
}


export const REACT_APP_FIREBASE_API_KEY='AIzaSyCnK3uVUkh2edofdVjPiar3kNIW3RBlZf4'
export const REACT_APP_FIREBASE_AUTH_DOMAIN='sldev-396702.firebaseapp.com'
export const REACT_APP_FIREBASE_PROJECT_ID='sldev-396702'
export const REACT_APP_FIREBASE_STORAGE_BUCKET='sldev-396702.appspot.com'
export const REACT_APP_FIREBASE_MESSAGEING_SENDER_ID='554713326867'
export const REACT_APP_FIREBASE_APP_ID='1:554713326867:web:52e7684f9ac224616726e6'

export const DEFAULT_COUNTRY_SELECTED: DropdownOption<number> = {label: 'USA', value: 231}

export const NOT_AVAILABLE = 'N/A'