import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../utils/https";

export const fetchLanguagesList = createAsyncThunk('get/language', async () => {
        try{
        const response = await get('language');
        console.log(response.data);
        return response.data;

        }
        catch(err) {
        console.log(err)
        }
     }
  );