import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUserProfile } from '../../models/user-profile.model'
import { HttpResponseType } from '../../models/https.model'
import { fetchUserProfile } from '../../views/pages/profile/profile.service'

interface IUserProfileState {
    userProfile: IUserProfile | null
}

const defaultState: IUserProfileState = {
  userProfile: null
}

export const UserProfileReducer = createSlice({
  name: 'UserProfileReducer',
  initialState: defaultState,
  reducers: {
  },
  extraReducers(builder) {
      builder.
        addCase(fetchUserProfile.fulfilled, (state: IUserProfileState, action: PayloadAction<HttpResponseType<IUserProfile> | undefined>) => {
          if(action.payload) {
            state.userProfile = action.payload.data
          }
        })
  },
})

export default UserProfileReducer.reducer