import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import React, { BaseSyntheticEvent, ChangeEventHandler, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Header } from "./header";
import "./styles.scss";
import { Footer } from "../contest/footer";
import { Step } from "../../../shared/models/steppers.model";
import { StepperBreadCrumb } from "../../../shared/components/stepper-breadcrumbs";
import { StepperFooterButtons } from "../../../shared/components/stepper-footer-buttons";
import { AppDispatch, RootState } from "../../../redux-store";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchCountries, fetchStates } from "../../../redux-store/configurations/configurations.service";
import { ICityOutput, ICountryOutput, IStateOutput } from "../../../models/configurations.model";
import { DEFAULT_COUNTRY } from "../../../constants/constants";
import Select from 'react-select';
import { convertToSelectOptions } from "../../../utils/app-utils";
import { SelectValueType } from "../../../models/common.model";
import { SyntheticEventData } from "react-dom/test-utils";

const NewUser = () => {

    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate();

    const logoRef = React.createRef<any>();
    const bannerRef = React.createRef<any>();

    const steps: Step[] = [{
        step: 1,
        title: "User Details",
        info: 'Provide User Name, Address and specify the State & City'
    },
    {
        step: 2,
        title: "User Credentials Info",
        info: 'Provide Email and Phone number. You are providing here will be the primary login user id'
    },
    ];

    const _states = (state: RootState) => state.ConfigurationReducer.states
    const states = useSelector(_states)

    const _cities = (state: RootState) => state.ConfigurationReducer.cities
    const cities = useSelector(_cities)

    const [currentStep, setCurrentStep] = useState<Step>(steps[0]);

    const [accountTitle, setAccountTitle] = useState<string>()
    const [accountAddress, setAccountAddress] = useState<string>()

    const [selectedCountry] = useState<ICountryOutput>(DEFAULT_COUNTRY)
    const [selectedState, setSelectedState] = useState<SelectValueType<IStateOutput>>();
    const [selectedCity, setSelectedCity] = useState<SelectValueType<ICityOutput>>();
    const [ownerName, setOwnerName] = useState<string>()
    const [ownerEmail, setOwnerEmail] = useState<string>()

    const [logo, setLogo] = useState<File>()
    const [logoFile, setLogoFile] = useState<any>()

    const [banner, setBanner] = useState<File>()
    const [bannerFile, setBannerFile] = useState<any>()



    const onClickStep = (step: Step) => {
        setCurrentStep(step);
    };

    useEffect(() => {
        dispatch(fetchStates(selectedCountry.id))
    }, [])

    useEffect(() => {
        if (selectedState) {
            dispatch(fetchCities(selectedState.value.id))
        }
    }, [selectedState])

    const nextStep = () => {
        if (steps.length !== currentStep.step) {
            setCurrentStep(steps[currentStep.step]);
        }
    };

    const prevStep = () => {
        if (1 !== currentStep.step) {
            setCurrentStep(steps[currentStep.step - 1]);
        }

    };

    const [showAccountDetails, setShowAccountDetails] = useState(true);
    const [showAccountOwnerInfo, setAccountOwnerInfo] = useState(true);


    const onStateChange = (state: SelectValueType<IStateOutput> | null) => {
        setSelectedCity(undefined)
        if(state === null) setSelectedState(undefined)
        else setSelectedState(state);
    }

    const onCityChanged = (city: SelectValueType<ICityOutput> | null) => {
        if(city === null) setSelectedCity(undefined)
        else  setSelectedCity(city);
    }


    const handleStep1Submit = () => {
        nextStep();
        return;
    };

    const createAccount = () => {
        //Create Account
    }

    const stepContinue = (step: Step) => {
        switch (step.title) {
            case steps[0].title:
                handleStep1Submit();
                break;
            case steps[1].title:
                createAccount()
                break;
            default:
                break;
        }
    };

    const cancelStep = (step: Step) => {
        switch (step.title) {
            case steps[0].title:
                setAccountTitle("");
                setAccountAddress("");

                break;

            case steps[1].title:
                prevStep();
                break;
            default:
                break;
        }
    };

    const onLogoSelect = () => {
        if(logoRef.current) logoRef.current.click()
        // setLogo(logo)
    }

    const onBannerSelect = () => {
        if(bannerRef.current) bannerRef.current.click()
    }

    const addPreview = (file:File, callback: any) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", function (e) {
            if(this.result) {
                callback(this.result)
            }
        }); 
    }

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target?.files.length >0) {
            setLogo(event.target.files[0])
            addPreview(event.target.files[0], setLogoFile)
        }
    };

    const onBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target?.files.length >0) {
            setBanner(event.target.files[0])
            addPreview(event.target.files[0], setBannerFile)
        }
    };

    return (
        <div>
            <StepperBreadCrumb steps={steps} currentStep={currentStep} onClickStep={onClickStep} />
            <div className="sl-step-container">
                <Header
                    currentStep={currentStep.step}
                    onEditClick={() => {}}
                    queryNumber={currentStep.step}
                    queryHeading={currentStep.title}
                    querySubHeading={currentStep.info || ''}
                    showContent={showAccountDetails}
                    onClick={() => setShowAccountDetails(!showAccountDetails)}
                />
                {
                    currentStep.title === steps[0].title && <div className="sl-admin-page">
                        <div className="sl-new-item-form-container">

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Account Title</span>
                                        <span className="contest-sub-text">
                                            Provide a account name and limit your words upto 20
                                            characters.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                        value={accountTitle}
                                        placeholder="Account Name"
                                        type="text"
                                        onChange={(e) => setAccountTitle(e.target.value)}
                                        className="text-input"
                                        required
                                    />
                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Address </span>
                                        <span className="contest-sub-text">
                                            Up to 500 characters maximum.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">

                                    <textarea
                                    value={accountAddress}
                                        placeholder="write your address here..."
                                        style={{
                                            resize: "none",
                                            overflow: "scroll",
                                            paddingRight: 60,
                                        }}
                                        className="text-multi-input"
                                        required
                                        onChange={(e) => setAccountAddress(e.target.value)}
                                    />
                                    <span className="contest-box-sub-text">
                                        You can edit this for any changes, will be posted as
                                        an update on the same thread.
                                    </span>
                                </div>
                            </div>

                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Logo </span>
                                        <span className="contest-sub-text">
                                            Choose Account Logo (size propotion should be 1:1)
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <input type="file" ref={logoRef} className="d-none" id="logo" onChange={(evnt) => onFileInputChange(evnt)} />
                                            <Button className="btn" type="button" onClick={onLogoSelect}>Select Logo</Button>
                                        </div>
                                        <div className="col-8">
                                            {
                                               logo &&  <img src={logoFile} style={{height: 80, width: 80}} />

                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Banner </span>
                                        <span className="contest-sub-text">
                                            Choose Account Banner (size propotion should be 1:3)
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <input type="file" ref={bannerRef} className="d-none" id="banner" onChange={(evnt) => onBannerChange(evnt)} />
                                            <Button className="btn" type="button" onClick={onBannerSelect}>Select Banner</Button>
                                        </div>
                                        <div className="col-8">
                                            {
                                               banner &&  <img src={bannerFile} style={{height: 50, width: 150}} />

                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">State & City</span>
                                        <span className="contest-sub-text">
                                            This will be your primary market. You can target
                                            only one state at once.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div
                                        style={{
                                            display: "inline-block",
                                            position: "relative",
                                        }}
                                    >
                                        <span className="contest-basics"> State </span>

                                        <Select
                                            options={convertToSelectOptions<IStateOutput>(states, "id", "name")}
                                            value={selectedState}
                                            closeMenuOnSelect={false}
                                            onChange={onStateChange}
                                            isMulti={false}
                                            styles={{
                                                control: (provided: any, state: any) => ({
                                                    ...provided,
                                                    borderColor: 'grey',
                                                }),
                                            }}
                                            className='text-input'
                                            required
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "inline-block",
                                            position: "relative",
                                        }}
                                    >
                                        <span className="contest-basics"> City</span>
                                        <Select
                                            options={convertToSelectOptions<ICityOutput>(cities, "id", "name")}
                                            value={selectedCity}
                                            closeMenuOnSelect={false}
                                            onChange={onCityChanged}
                                            isMulti={false}
                                            styles={{
                                                control: (provided: any, state: any) => ({
                                                    ...provided,
                                                    borderColor: 'grey',
                                                }),
                                            }}
                                            className='text-input'
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    currentStep.title === steps[1].title && <div className="sl-admin-page">
                        <div className="sl-new-item-form-container">

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Name</span>
                                        <span className="contest-sub-text">
                                            Provide a account name and limit your words upto 20
                                            characters.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                    value={ownerName}
                                        placeholder="name"
                                        type="text"
                                        onChange={(e) => setOwnerName(e.target.value)}
                                        className="text-input"
                                        required
                                    />
                                </div>
                            </div>

                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Email</span>
                                        <span className="contest-sub-text">
                                            {" "}
                                            Provide a account email .
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                        value={ownerEmail}
                                        placeholder="email"
                                        type="email"
                                        onChange={(e) => setOwnerEmail(e.target.value)}
                                        className="text-input"
                                        required
                                    />
                                </div>
                            </div>
                            <hr className="grey-border" style={{ marginLeft: "4%" }} />
                        </div>
                    </div>
                }

            </div>

            <div>
                <StepperFooterButtons
                    totalNoOfSteps={2}
                    currentStep={currentStep}
                    onClickCancel={cancelStep}
                    onClickContinue={stepContinue}
                />
            </div>
        </div>
    );
};

export default NewUser;
