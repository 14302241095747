import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from "react-bootstrap"

import { GrAdd } from 'react-icons/gr'
import { MdOutlineGrading } from 'react-icons/md'
import { GrFilter } from 'react-icons/gr'

import './contest-list.scss'
import ContestsLists from './contest-list'
import { useLocation, useNavigate } from 'react-router-dom'
import 'react-contexify/ReactContexify.css'
import { Bs1Circle, Bs2Circle } from 'react-icons/bs'

const ContestList = () => {
    
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate(); 
  
    useEffect(() => {
        if (location.state && location.state.formSubmitted) {
            setShowModal(true);
        }
    }, [location]);

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleBackToHome = () => {
        
        navigate("/")
        setShowModal(false);
    };
    

    const onNewContest = () => {
        navigate('/new-contest')
    }

    return (

        <>
      
        <div className='search-container'>
            <div className="row sl-search-container">
                <div className="col-12 col-md-10 ">
                    <Form.Control type="search" placeholder="Search contests, applicants" />
                </div>
               
                <div className="col-12 col-md-2">
                    <Button variant="primary">Search</Button>
                </div>

                
            </div>

            <div className="row">
                <div className='col-12 col-md-8'>
                    <div className="sl-welcome-note">
                        <span className='sl-title'>Contests</span>
                        <span className='sl-sub-text'> A comprehensive list of contests hosted across a specific region, providing an overview of your diverse and engaging competition offerings.</span>
                    </div>
                </div>
                {/* <div className="col-12 col-md-4">
                    <div className="sl-user-location">
                        <DropdownButton id="dropdown-basic-button" title="California, United States">
                            <Dropdown.Item href="#/action-1">Mumbai, Maharashtra</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Hyderabad, Telangana</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Chennai, Tamilnadu</Dropdown.Item>
                        </DropdownButton>

                    </div>
                </div> */}
            </div>

            <div className='new-btn-container'>
                <div className='sl-new-contest'>
                    <button className='new-item-btn' onClick={onNewContest}> <GrAdd></GrAdd> New Contest </button>

                </div>
                <div className='sl-grading'>
                    <MdOutlineGrading></MdOutlineGrading> Grading
                </div>


            </div>
            <div className='sl-filter'>
                <GrFilter></GrFilter> Filter
            </div>
            <ContestsLists />
            
            
            <div>
            <Modal  show={showModal} onHide={handleCancel} centered>
            
            <Modal.Header className="sl-contest-created-success-msg" closeButton></Modal.Header>
            <Modal.Header className="sl-contest-created-success-msg">
                <Modal.Title style={{paddingLeft: 10}}><b>Success! Your contest is ready.</b><br></br><h6>Your contest has been created and is now live on our application.</h6></Modal.Title>
                {/* <Image className='contest-created-img' src={contestCreated}/> */}
            </Modal.Header>
            <div style={{margin: 20}}>
            <Modal.Body>
               <div><h5>Let the creative journey begin!</h5></div><br></br>
                <div className='footer-modal'><Bs1Circle className='b1Circle'/><div className="modal-sub-text"> Get ready to welcome participants and see their creativity shine.</div></div><br></br>
                <div className='footer-modal'><Bs2Circle className='b2Circle'/><div className="modal-sub-text"> Share the exciting news with your network and stay tuned for contest entries.</div></div>
                
            </Modal.Body>
            </div>
            <Modal.Footer className='footer-modal'>
                    <Button variant='outline-light' className="review-submit" onClick={handleBackToHome}>Back to Home</Button>
                    <Button variant='outline-light' className="cancel-button" onClick={handleCancel}>Cancel</Button>
                </Modal.Footer>
           </Modal>
           </div>
        </div>

        </>
    )
}

export default ContestList;