export type ROLE = 'SL_ADMIN' | 'SL_ACCOUNT_OWNER' | 'SL_ACCOUNT_ADMIN' | 'SL_ACCOUNT_USER' | 'SL_WRITER'

export const isSLAdmin = (role: ROLE) => {
    if(role === 'SL_ADMIN') return true

    return false
}

export const isAccountOwner = (role: ROLE) => {
    if(role === 'SL_ACCOUNT_OWNER') return true

    return false
}

export const isAccountAdmin = (role: ROLE) => {
    if(role === 'SL_ACCOUNT_ADMIN') return true

    return false
}

export const isAccountUser = (role: ROLE) => {
    if(role === 'SL_ACCOUNT_USER') return true

    return false
}

export function convertToSelectOptions<T> (data:T[], value: keyof T, label: keyof T){
    return data.map(item => {
        return {
            label: item[label] as any,
            value: item
        }
    })
}