import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios";
import { HttpResponseType } from "../../models/https.model";
import { get } from "../../utils/https";
import { ICityOutput, ICountryOutput, IStateOutput } from "../../models/configurations.model";


export const fetchCountries = createAsyncThunk('fetch/countries', async () => {
    try {
        const response: AxiosResponse<HttpResponseType<ICountryOutput[]>> = await get('config/countries');
        return response.data;
    }
    catch (err) {
        console.log(err)
    }
}
);

export const fetchStates = createAsyncThunk('fetch/states', async (countryId: number) => {
    try {
        const response: AxiosResponse<HttpResponseType<IStateOutput[]>> = await get(`config/states/${countryId}`);
        return response.data;
    }
    catch (err) {
        console.log(err)
    }
}
);

export const fetchCities = createAsyncThunk('fetch/cities', async (stateId: number) => {
    try {
        const response: AxiosResponse<HttpResponseType<ICityOutput[]>> = await get(`config/cities/${stateId}`);
        return response.data;
    }
    catch (err) {
        console.log(err)
    }
}
);
