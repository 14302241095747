import { createAsyncThunk } from "@reduxjs/toolkit"
import { get } from "../../../utils/https";
import { IAccountPagableReponse } from "../../../models/account.model";
import { AxiosResponse } from "axios";
import { HttpResponseType } from "../../../models/https.model";
import { PaginationQueryParams } from "../../../models/common.model";

export const fetchAccounts = createAsyncThunk('fetch/accounts', async (pagination: PaginationQueryParams) => {
        try{
        const response: AxiosResponse<HttpResponseType<IAccountPagableReponse>> = await get(`account?page=${pagination.page}&itemsPerPage=${pagination.itemsPerPage}`);
        return response.data;
        }
        catch(err) {
        console.log(err)
        }
     }
);
