import { initializeApp } from "firebase/app";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_MESSAGEING_SENDER_ID, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET } from "../constants/constants";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY, // process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN, //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,  //process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,  // process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGEING_SENDER_ID, // process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
  appId:REACT_APP_FIREBASE_APP_ID // process.env.REACT_APP_FIREBASE_APP_ID
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const verifyPasswordResetObbCode = (oobCode: string): Promise<string> => {
 const response = verifyPasswordResetCode(auth, oobCode)
   return response
}

export const resetPassword = (oobCode: string, password: string): Promise<void> => {

  return confirmPasswordReset(auth,oobCode, password)
}

export default app;