import { createAsyncThunk } from "@reduxjs/toolkit"
import { post } from "../../../utils/https";
import { ContestInput } from "../../../models/contest.model";
import { showSuccessToast } from "../../../services/toast.service";

export const fetchContestData = createAsyncThunk('post/contest', async (contestData: ContestInput) => {
        try {
           const response = await post('contest',contestData);
           console.log(response.data);
           return response.data;
        }
        catch(err) {
         console.log(err)
        }
     }
  );
