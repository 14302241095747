import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { AiOutlineFolderView } from "react-icons/ai";
import { GiBackwardTime } from "react-icons/gi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IContestListState } from "../../../redux-store/contests/contest.reducer";
import React from "react";
import { NOT_AVAILABLE } from "../../../constants/constants";
import { transformToAmount } from "../../../services/transform.services";

export const columns = (tableData: any, showContestInfo: any, showApplications: any) => {
    return [
        {
            name: 'Contests name',
            selector: (row: IContestListState) => row.title,
            sortable: true
        },
        {
            name: 'Prize/Reward',
            selector: (row: IContestListState) => row.firstPrize,
            sortable: true,
            width: '180px',
            cell: (row: IContestListState) => (
                <React.Fragment>
                    {
                        <div className="mt-2 mb-2">
                            <div>
                                <span className="column-key">First Prize: </span>
                                <span>{row.firstPrize || NOT_AVAILABLE} </span>
                            </div>
                            <div>
                                <span className="column-key">Second Prize: </span>
                                <span>{row.sencondPrize || NOT_AVAILABLE} </span>
                            </div>
                            <div>
                                <span className="column-key">Third Prize: </span>
                                <span>{row.thirdPrize || NOT_AVAILABLE} </span>
                            </div>
                        </div>
                    }
                </React.Fragment>
            )
        },
        {
            name: 'Entry Fees & Timeline',
            width: '180px',
            cell: (row: IContestListState) => (
                <React.Fragment>
                    {
                        row.deadlines && row.deadlines.length > 0 ? <>
                            {
                                row.deadlines.map(deadline => <React.Fragment key={`deadline-${deadline.id}-${deadline.contestId}`}>
                                    {transformToAmount(deadline.fees)} | {deadline.deadline}
                                </React.Fragment>)
                            }
                        </> :
                            <>{NOT_AVAILABLE}</>
                    }
                </React.Fragment>
            ),
        },
        {
            name: 'Contest Status',
            selector: (row: IContestListState) => row.status,
            sortable: true
        },
        {
            name: 'Created Date',
            cell: (row: IContestListState) => { return moment(row.createdAt).format('DD   MMM   YYYY')},
            sortable: true
        },
        {
            name: 'Actions',
            cell: (row: IContestListState) => (
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        ...
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='drop-menu'>
                        <Dropdown.Item className='view' onClick={() => showContestInfo(row)} >
                            <AiOutlineFolderView className='folderView'></AiOutlineFolderView> View Contest Info
                        </Dropdown.Item>
                        <Dropdown.Item className='view-applicants' onClick={() => showApplications(row)}> <GiBackwardTime className='backwardTime '></GiBackwardTime>View Applicants</Dropdown.Item>
                        <Dropdown.Item className='delete' href="#/action-5"><RiDeleteBin6Line className='deleteBin'></RiDeleteBin6Line>Delete</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            )
        },

    ]
}