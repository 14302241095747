import { useEffect, useState } from "react";
import Header from "../../../shared/components/header";

import LandingImage from "../../../images/landing-page.png";
import Maskgroup from "../../../images/maskgroup.png";
import Saly from "../../../images/Saly.png";
import "./style.scss";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { resetPassword, verifyPasswordResetObbCode } from "../../../services/firebase.service";

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { showErrorToast, showSuccessToast } from "../../../services/toast.service";
import { FIREBASE_AUTH_ERROR_CODES } from "../../../authentication/auth-code";
import { ToastContainer } from "react-toastify"
import Login from "../login";

const UpdatePassword = () => {
    const params = useParams()
    const navigate = useNavigate()

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [userFirebaseEmail, setUserFirebaseEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const formSchema = Yup.object().shape({
        email: Yup.string().email()
        .oneOf([userFirebaseEmail],'Provided wrong email'),
        password: Yup.string()
            .required('Password is mendatory')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\$%\^&\*])(?=.{8,})/, "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
        confirmPassword: Yup.string()
            .required('Password is mendatory')
            .oneOf([Yup.ref('password')], 'Passwords does not match'),
    })

    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, handleSubmit, reset, formState } = useForm(formOptions)
    const { errors } = formState


    const onLoginPress = () => {
        setShowLogin(true)
    }

    const onHideLogin = () => {
        setShowLogin(false)
    }

    const onTryForFreePress = () => {

    }

    useEffect(() => {
        console.log('AUTH')
        if (params['oobCode']) {
            verifyPasswordResetObbCode(params['oobCode'])
                .then(email => {
                    setEmail(email)
                    setUserFirebaseEmail(email)
                })
                .catch((error) => {
                    console.log(error.message)
                    if(error.code === FIREBASE_AUTH_ERROR_CODES.EXPIRED_OOB_CODE) {

                        showErrorToast("Reset Password code is expired. Please try again!")
                    }
                })
        }
    }, [])

    const onValueChange = (ev: React.ChangeEvent<any>) => {
        if (ev.target.name === 'email') setEmail(ev.target.value)

        else if (ev.target.name === 'password') setPassword(ev.target.value)

        else if (ev.target.name === 'confirmPassword') setConfirmPassword(ev.target.value)
    }

    function onSubmit(data: any) {

        if (params['oobCode']) {
            resetPassword(params['oobCode'], data.password)
            .then(() => {
                showSuccessToast('Password Updated Successfully!. Please login to continue')
                navigate('/app-info')
            })
            .catch((errors) => {
                console.log(errors)
            })
        }

        return false
      }

    return (
        <>
        <ToastContainer />
            <Header
                onLoginPress={onLoginPress}
                onTryForFreePress={onTryForFreePress}
            />
            <img
                src={LandingImage}
                className="img-fluid"
                alt="Responsive image"
            ></img>

            <div className="row">
                <div className="col-md-6 col-lg-12 col-xl-12">
                    <div className="update-password-modal">
                        <div className="update-pwd-img d-none d-lg-block">
                            <img
                                className="img-fluid update-pwd-bg-img"
                                src={Maskgroup}
                                alt="Update Password Image"
                            />
                        </div>
                        <div className="update-pwd-container">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <h3 className="greet">
                                        <b>Hello, Welcome Back</b> <span>👋</span>
                                    </h3>
                                    <p className="info">
                                        Please enter your email address and add new password to continue.
                                    </p>


                                    <label className="label" typeof="email">
                                        Email Address
                                    </label>
                                    <br />
                                    <input
                                        type="email"
                                        value={email}
                                        disabled={true}
                                        placeholder="Sample@domain.com"
                                        {...register('email')}
                                        className={`input form-control ${errors.email ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                    <label className="label" typeof="password">
                                        New Password
                                    </label>
                                    <br />
                                    <input

                                        type="password"
                                        value={password}
                                        placeholder="Password"
                                        {...register('password')}
                                        onChange={(event) => { onValueChange(event) }}
                                        className={`input form-control ${errors.password ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.password?.message}</div>

                                    <label className="label" typeof="password">
                                        Confirm Password
                                    </label>
                                    <br />
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        placeholder="Confirm Password"
                                        {...register('confirmPassword')}
                                        onChange={(event) => { onValueChange(event) }}
                                        className={`input form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                    />

                                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>

                                    <div className="btn-container">
                                        <button className="login-btn m-2">
                                            Update
                                        </button>
                                        <button type="button" className="signup-btn m-2" onClick={onLoginPress}>
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <Login show={showLogin} onHideLogin={onHideLogin}/>

        </>
    );
};

export default UpdatePassword;
