import { Button, Form } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"
import './styles.scss'
import { fetchAccounts } from "./account-services";
import { AppDispatch, RootState } from "../../../redux-store";
import { useDispatch, useSelector } from "react-redux";
import { GrAdd, GrFilter } from "react-icons/gr";
import { columns } from "./accounts-columns";
import DataTable from 'react-data-table-component';
import { PaginationQueryParams } from "../../../models/common.model";

interface footerProps {

    onClickContinue?: () => void
    onClickCancel?: () => void
    currentStep?: number
}

const Accounts = (props: footerProps) => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const _accounts = (state: RootState) => state.AccountsReducer.accounts
    const accounts = useSelector(_accounts)

    const _totalAccounts = (state: RootState) => state.AccountsReducer.totalAccounts
    const totalAccounts: number = useSelector(_totalAccounts)

    const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);


    const getAccounts = (page: number, _perPage: number) => {
        const pagination: PaginationQueryParams = {
            page: page,
            itemsPerPage: _perPage
        }
		setLoading(true);
        dispatch(fetchAccounts(pagination)).unwrap().then(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getAccounts(1, perPage)
    }, [])

    const onNewAccount = () => {
        navigate('/new-account')
    }

    const handlePageChange = (page: number) => {
		getAccounts(page, perPage);
	};

	const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        getAccounts(page,newPerPage);
	};

    return (
        <div className='search-container'>
            <div className="row sl-search-container">
                <div className="col-12 col-md-10 ">
                    <Form.Control type="search" placeholder="Search contests, applicants" />
                </div>
               
                <div className="col-12 col-md-2">
                    <Button variant="primary">Search</Button>
                </div>

                
            </div>

            <div className="row">
                <div className='col-12 col-md-12'>
                    <div className="sl-welcome-note">
                        <span className='sl-title'>Accounts</span>
                        <span className='sl-sub-text'> A comprehensive list of all accounts created under storyline.</span>
                    </div>
                </div>
            </div>

            <div className='new-btn-container'>
                <div className='sl-new-contest'>
                    <button className='new-item-btn' onClick={onNewAccount}> <GrAdd></GrAdd> New Account </button>

                </div>
            </div>
            <div className='sl-filter'>
                <GrFilter></GrFilter> Filter
            </div>
            <hr />
            <div className="sl-list-contaner">
            {
                accounts.length > 0 && <DataTable
                noTableHead
                columns={columns()}
                data={accounts}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalAccounts}
                paginationRowsPerPageOptions={[10,25,50]}
                paginationPerPage={perPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
            }

            </div>

        </div>
    )
}

export default Accounts