import { Button, Col, Dropdown, DropdownButton, Form, FormControl, Image, ModalDialog, Row } from "react-bootstrap"
import './style.scss'
import { Bs1Circle, Bs2Circle, BsStackOverflow } from 'react-icons/bs'
import { RxCross2 } from 'react-icons/rx'
import GroupImg from '../../../images/group.png'
import DashboardSummaryImg from '../../../images/dashboard-summary.png'
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"
import { AppDispatch, RootState } from "../../../redux-store"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserProfile, saveLoggedInUserProfile } from "../profile/profile.service"
import { useAuth } from "../../../hooks/providers/auth.provider"

// import contestCreated from '../../../images/contestCreated.png';

const Dashboard = () => {


    const auth = useAuth()
  
  
    
    
    return <div className="sl-dashboard">
        <div className="row">
            <div className="col-12 col-md-8">
                <div className="row sl-search-container">
                    <div className="col-12 col-md-10 ">
                        <Form.Control type="search" placeholder="name@example.com" />
                    </div>
                    <div className="col-12 col-md-2">
                        <Button variant="primary">Search</Button>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12 col-md-8'>
                        <div className="sl-welcome-note">
                            <span className='sl-title'>Welcome Back, {auth?.user?.displayName}</span>
                            <span className='sl-sub-text'> This hub is your control center, designed to make managing contests a breeze.</span>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-4">
                        <div className="sl-user-location">
                            <DropdownButton id="dropdown-basic-button" title="California, United States">
                                <Dropdown.Item href="#/action-1">Mumbai, Maharashtra</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Hyderabad, Telangana</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Chennai, Tamilnadu</Dropdown.Item>
                            </DropdownButton>

                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="sl-create-contest-container">
                            <img className='sl-create-contest-img' src={GroupImg} alt='Create Content' />
                            <div className="sl-create-contest-overview">
                                <span className='sl-create-contest-title'>Start creating contests</span>
                                <p className="sl-create-contest-sub-txt">
                                    Create a contest and embark on a journey where your imagination knows no bounds. Publish a contest and invite all writers to  create a compelling narrative that will captivate hearts and minds. Let your creativity flow, and who knows, your story might just be the next masterpiece that leaves readers enthralled and inspired.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4 sl-dashbord-summary">
                <div className="sl-dashboard-summary-header row">
                    <div className="col-9 sl-dashboard-summary-header-txt">
                        Grading Summary
                    </div>
                    <div className="col-1 text-center">
                        <BsStackOverflow />
                    </div>
                    <div className="col-1 text-center">
                        <RxCross2 />
                    </div>
                </div>
                <div className="row sl-dashboard-summary-overview">
                    <img className='sl-dashboard-summary-img' src={DashboardSummaryImg} alt='amoeba' />
                    <span className='sl-dashboard-summary-title'>Welcome to the Side Panel Insights</span>
                    <span className='sl-dashboard-summary-sub-txt'>Your gateway to uncovering valuable information about ongoing work and scripts submitted for the contest</span>
                    <div className="row sl-dashboard-summary-info">
                        <div className="col-12">

                            <p>Within this intuitive feature,</p>
                            <p>
                                1. Administrators gain a comprehensive view of all incompleted tasks and scripts,
                                enabling efficient tracking and follow-ups. Dive deeper into each project or submission
                                to access vital insights, such as progress status, contributors involved, and estimated
                                completion timelines.

                            </p>
                            <p>2. For contest administrators, this tool is a game-changer.
                                It provides a centralized hub to evaluate, grade, and provide constructive
                                feedback on contest submissions. Gain valuable script metrics, view submission history,
                                and access contextual information to make informed grading decisions</p>

                            <p>In a world where insights drive excellence, the Side Panel is your trusted ally, empowering you to manage,
                                grade, and elevate the quality of work with precision and ease
                            </p>
                            <div>
                            </div>
                        </div>
                        <Button className='addscripts' variant="primary" size="sm">
                            + Add scipts
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard