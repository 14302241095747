import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Header } from "./header";
import "./styles.scss";
import { Step } from "../../../shared/models/steppers.model";
import { StepperBreadCrumb } from "../../../shared/components/stepper-breadcrumbs";
import { StepperFooterButtons } from "../../../shared/components/stepper-footer-buttons";
import { AppDispatch, RootState } from "../../../redux-store";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchStates } from "../../../redux-store/configurations/configurations.service";
import { ICityOutput, ICountryOutput, IStateOutput } from "../../../models/configurations.model";
import { COUNTRY_USA, DEFAULT_COUNTRY } from "../../../constants/constants";
import Select from 'react-select';
import { convertToSelectOptions } from "../../../utils/app-utils";
import { SelectValueType } from "../../../models/common.model";
import { NewAccountRequestPayloadType, addNewAccount } from "./account-services";
import { fetchAccounts } from "../accounts/account-services";

const NewAccount = () => {

    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate();

    const logoRef = React.createRef<any>();
    const bannerRef = React.createRef<any>();

    const steps: Step[] = [{
        step: 1,
        title: "Account Details",
        info: 'Provide Account title, Address, and specify the State & City'
    },
    {
        step: 2,
        title: "Account Owner Info",
        info: 'Provide Account Owner Information, Email you are proving here will be the primary login user id'
    },
    {
        step: 3,
        title: "Review And Submit",
        info: "Summary",
      },

    ];

    const _states = (state: RootState) => state.ConfigurationReducer.states
    const states = useSelector(_states)

    const _cities = (state: RootState) => state.ConfigurationReducer.cities
    const cities = useSelector(_cities)

    const [currentStep, setCurrentStep] = useState<Step>(steps[0]);

    const [accountTitle, setAccountTitle] = useState<string>('')
    const [accountAddress, setAccountAddress] = useState<string>('')

    const [selectedCountry] = useState<ICountryOutput>(COUNTRY_USA)
    const [selectedState, setSelectedState] = useState<SelectValueType<IStateOutput>>();
    const [selectedCity, setSelectedCity] = useState<SelectValueType<ICityOutput>>();
    const [ownerName, setOwnerName] = useState<string>('')
    const [ownerEmail, setOwnerEmail] = useState<string>('')

    const [logo, setLogo] = useState<File>()
    const [logoFile, setLogoFile] = useState<any>()

    const [banner, setBanner] = useState<File>()
    const [bannerFile, setBannerFile] = useState<any>()



    const onClickStep = (step: Step) => {
        setCurrentStep(step);
    };

    useEffect(() => {
        dispatch(fetchStates(selectedCountry.id))
    }, [])

    useEffect(() => {
        if (selectedState) {
            dispatch(fetchCities(selectedState.value.id))
        }
    }, [selectedState])

    const nextStep = () => {
        if (steps.length !== currentStep.step) {
            setCurrentStep(steps[currentStep.step]);
        }
    };

    const prevStep = () => {
        if ( currentStep.step > 1 ) {
            setCurrentStep(steps[currentStep.step - 2]);
        }

    };

    const [showAccountDetails, setShowAccountDetails] = useState(true);
    const [showAccountOwnerInfo, setShowAccountOwnerInfo] = useState(true);


    const onStateChange = (state: SelectValueType<IStateOutput> | null) => {
        setSelectedCity(undefined)
        if(state === null) setSelectedState(undefined)
        else setSelectedState(state);
    }

    const onCityChanged = (city: SelectValueType<ICityOutput> | null) => {
        if(city === null) setSelectedCity(undefined)
        else  setSelectedCity(city);
    }


    const handleStep1Submit = () => {
        
        if(accountTitle === '')
        {
            alert('account Title is required');
        }
        else if(accountAddress === '')
        {
            alert('account description is required');
        }
        else if(!logo)
        {
            alert('logo is required');
        }
        else if(!banner)
        {
            alert('Banner is required');
        }
        else if(!selectedState )
        {
            alert('please select state');
        }
        else if(!selectedCity)
        {
            alert('please select city');
        }
        else
        {
        nextStep();
        return;
        }
    };

    const handleStep2Submit = () => {
        if(ownerName === '')
        {
            alert('name is required');
        }
        else if(ownerEmail === '')
        {
            alert('Email is required');
        }
        else{
        nextStep();
        return;
        }
    }

    const createAccount = async () => {
        const payload: NewAccountRequestPayloadType = {
            logo: logo,
            banner: banner,
            account: {
                address: accountAddress || '',
                cityId: selectedCity?.value.id || 0,
                stateId: selectedState?.value.id || 0,
                countryId: selectedCountry.id || 0,
                banner: '',
                logo: '',
                name: accountTitle || '',
                status: 'CREATED',
                ownerEmail: ownerEmail || '',
                ownerName: ownerName || '',
                usersCount: 0
            }
        }
        await dispatch(addNewAccount(payload)).unwrap()
        dispatch(fetchAccounts({page: 1, itemsPerPage: 10}))
        navigate('/accounts')

    }

    const stepContinue = (step: Step) => {
        switch (step.title) {
            case steps[0].title:
                handleStep1Submit();
                break;
            case steps[1].title:
                handleStep2Submit()
                break;
            case steps[2].title:
                createAccount();
                break;
            default:
                break;
        }
    };

    const cancelStep = (step: Step) => {
        switch (step.title) {
            case steps[0].title:
                navigate("/accounts")
                break;

            case steps[1].title:
                prevStep();
                break;
            case steps[2].title:
                prevStep();
                 break;
            default:
                break;
        }
    };

    const onLogoSelect = () => {
        if(logoRef.current) logoRef.current.click()
        // setLogo(logo)
    }

    const onBannerSelect = () => {
        if(bannerRef.current) bannerRef.current.click()
    }

    const addPreview = (file:File, callback: any) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", function (e) {
            if(this.result) {
                callback(this.result)
            }
        }); 
    }

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target?.files.length >0) {
            setLogo(event.target.files[0])
            addPreview(event.target.files[0], setLogoFile)
        }
    };

    const onBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target?.files.length >0) {
            setBanner(event.target.files[0])
            addPreview(event.target.files[0], setBannerFile)
        }
    };

    return (
        <div>
            <StepperBreadCrumb steps={steps} currentStep={currentStep} onClickStep={onClickStep} />
            
                {
                    ( currentStep.title === steps[0].title || currentStep.step === steps[2].step) && 
                    <div>
                    <div className="sl-step-container">
                    <Header
                    currentStep={currentStep.step}
                    onEditClick={() => setCurrentStep(steps[0])}
                    queryNumber={steps[0].step}
                    queryHeading={steps[0].title}
                    querySubHeading={steps[0].info || ''}
                    showContent={showAccountDetails}
                    onClick={() => setShowAccountDetails(!showAccountDetails)}
                    />
                    {showAccountDetails &&
                    <div className="sl-admin-page">
                        <div className="sl-new-item-form-container">

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Account Title</span>
                                        <span className="contest-sub-text">
                                            Provide a account name and limit your words upto 20
                                            characters.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                        value={accountTitle}
                                        placeholder="Account Name"
                                        type="text"
                                        onChange={(e) => setAccountTitle(e.target.value)}
                                        className="text-input"
                                        required
                                        disabled={currentStep.step === steps[2].step ? true : false}
                                    />
                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Address </span>
                                        <span className="contest-sub-text">
                                            Up to 500 characters maximum.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">

                                    <textarea
                                        value={accountAddress}
                                        placeholder="write your address here..."
                                        style={{
                                            resize: "none",
                                            overflow: "scroll",
                                            paddingRight: 60,
                                        }}
                                        className="text-multi-input"
                                        required
                                        onChange={(e) => setAccountAddress(e.target.value)}
                                        disabled={currentStep.step === steps[2].step ? true : false}
                                    />
                                    <span className="contest-box-sub-text">
                                        You can edit this for any changes, will be posted as
                                        an update on the same thread.
                                    </span>
                                </div>
                            </div>

                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Logo </span>
                                        <span className="contest-sub-text">
                                            Choose Account Logo (size propotion should be 1:1)
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <input type="file" ref={logoRef} className="d-none" id="logo" onChange={(evnt) => onFileInputChange(evnt)} />
                                            <Button className="btn" type="button" onClick={onLogoSelect} disabled={currentStep.step === steps[2].step ? true : false}>Select Logo</Button>
                                        </div>
                                        <div className="col-8">
                                            {
                                               logo &&  <img src={logoFile} style={{height: 80, width: 80}} />

                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics"> Banner </span>
                                        <span className="contest-sub-text">
                                            Choose Account Banner (size propotion should be 1:3)
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <input type="file" ref={bannerRef} className="d-none" id="banner" onChange={(evnt) => onBannerChange(evnt)} />
                                            <Button className="btn" type="button" onClick={onBannerSelect} disabled={currentStep.step === steps[2].step ? true : false}>Select Banner</Button>
                                        </div>
                                        <div className="col-8">
                                            {
                                               banner &&  <img src={bannerFile} style={{height: 50, width: 150}} />

                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">State & City</span>
                                        <span className="contest-sub-text">
                                            This will be your primary market. You can target
                                            only one state at once.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div
                                        style={{
                                            display: "inline-block",
                                            position: "relative",
                                        }}
                                    >
                                        <span className="contest-basics"> State </span>

                                        <Select
                                            options={convertToSelectOptions<IStateOutput>(states, "id", "name")}
                                            value={selectedState}
                                            closeMenuOnSelect={true}
                                            onChange={onStateChange}
                                            isMulti={false}
                                            styles={{
                                                control: (provided: any, state: any) => ({
                                                    ...provided,
                                                    borderColor: 'grey',
                                                }),
                                            }}
                                            className='text-input'
                                            required
                                            isDisabled={currentStep.step === steps[2].step ? true : false}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "inline-block",
                                            position: "relative",
                                        }}
                                    >
                                        <span className="contest-basics"> City</span>
                                        <Select
                                            options={convertToSelectOptions<ICityOutput>(cities, "id", "name")}
                                            value={selectedCity}
                                            closeMenuOnSelect={true}
                                            onChange={onCityChanged}
                                            isMulti={false}
                                            styles={{
                                                control: (provided: any, state: any) => ({
                                                    ...provided,
                                                    borderColor: 'grey',
                                                }),
                                            }}
                                            className='text-input'
                                            required
                                            isDisabled={currentStep.step === steps[2].step ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    </div>
                    </div>
                }
                {
                    ( currentStep.title === steps[1].title || currentStep.step === steps[2].step ) && 
                    <div><div className="sl-step-container">
                    <Header
                    queryNumber={steps[1].step}
                    queryHeading={steps[1].title}
                    querySubHeading={steps[1].info || ''}
                    showContent={showAccountOwnerInfo}
                    onClick={() => setShowAccountOwnerInfo(!showAccountOwnerInfo)}
                    />
                    {
                        showAccountOwnerInfo &&
                    <div className="sl-admin-page">
                        <div className="sl-new-item-form-container">

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Name</span>
                                        <span className="contest-sub-text">
                                            Provide a account name and limit your words upto 20
                                            characters.
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                    value={ownerName}
                                        placeholder="name"
                                        type="text"
                                        onChange={(e) => setOwnerName(e.target.value)}
                                        className="text-input"
                                        required
                                        disabled={currentStep.step === steps[2].step ? true : false}
                                    />
                                </div>
                            </div>

                            <hr className="grey-border" />

                            <div className="row sl-form-item">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="contest-queries">
                                        <span className="contest-basics">Email</span>
                                        <span className="contest-sub-text">
                                            {" "}
                                            Provide a account email .
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <input
                                        value={ownerEmail}
                                        placeholder="email"
                                        type="email"
                                        onChange={(e) => setOwnerEmail(e.target.value)}
                                        className="text-input"
                                        required
                                        disabled={currentStep.step === steps[2].step ? true : false}
                                    />
                                </div>
                            </div>
                            <hr className="grey-border" style={{ marginLeft: "4%" }} />
                        </div>
                    </div>
                    }
                    </div></div>
                }

            

            <div>
                <StepperFooterButtons
                    totalNoOfSteps={3}
                    currentStep={currentStep}
                    onClickCancel={cancelStep}
                    onClickContinue={stepContinue}
                />
            </div>
        </div>
    );
};

export default NewAccount;
