import { RouteObject, defer } from "react-router-dom";
import Main from "../views/main/main";
import Dashboard from "../views/pages/dashboard";
import ContestList from "../views/pages/contest-list";
import Landing from "../views/pages/landing";
import About from "../views/pages/about-us/about";
import { AuthLayout } from "../shared/components/auth-layout";
import { auth } from '../services/firebase.service';
import ContestForm from "../views/pages/contest";
import Accounts from "../views/pages/accounts";
import ProfileSummary from "../views/pages/profile/profile-summary";
import NewAccount from "../views/pages/new-account";
import UpdatePassword from "../views/pages/update-password";
import Users from "../views/pages/users";
import NewUser from "../views/pages/new-user";

// import SignUp from "../views/pages/signup/signup-modal";

const getUserData = () => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(auth.currentUser);
    }, 3000)
    );
}

const routes: RouteObject[] = [
  {
    Component: AuthLayout,
    loader: () => defer({ userPromise: getUserData() }),
    children: [{
      path: "",
      Component: Main,
      children: [
        {
          path: "/",
          Component: Dashboard,
        },
        {
          path: "/new-contest",
          Component: ContestForm,
        },
        {
          path: "/contest-list",
          Component: ContestList,
        },
        {
          path: "/accounts",
          Component: Accounts,
        },
        {
          path: "/new-account",
          Component: NewAccount,
        },
        {
          path: "/profile-summary",
          Component: ProfileSummary,
        },
        {
          path: "/users",
          Component: Users,
        },
        {
          path: "/new-user",
          Component: NewUser,
        }
      ],
    },
    {
      path: "/app-info",
      Component: Landing,
    }  
    ]
  },
  {
    path: "/about",
    Component: About,
  },
  {
    path: "/update-password/:mode/:oobCode",
    Component: UpdatePassword,
  }
];

export default routes;
