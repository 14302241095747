import React, { useContext, useEffect, useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { MdKeyboardArrowDown } from 'react-icons/md'
import ContestImg from '../../../../images/city.jpg'
import { IContestListState } from '../../../../redux-store/contests/contest.reducer';
import { AppDispatch, RootState, store } from '../../../../redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { ContestStoryMapUpdateRequest, StoryContestStatusEnum, fetchContesApplication, submitContesApplicationStatus } from '../contest-list.service';
import { IContestStories, IStoryContestOutput } from '../contest.model';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { Accordion, Button, Card } from 'react-bootstrap';
import { ContextAwareToggle } from '../../../../shared/components/accordian-toggle';
import { IoIosArrowForward } from 'react-icons/io';
import { transformToAmount } from '../../../../services/transform.services';
import { showSuccessToast } from '../../../../services/toast.service';
import { VoidFuntion, VoidFuntionWithOneArg } from '../../../../models/common.model';
import { MdHistory } from "react-icons/md";
import { ImFilesEmpty } from "react-icons/im";

interface ContestDetailsProps {
    contest: IContestListState | undefined
    showContestInfo: VoidFuntionWithOneArg<IContestListState>
}

const BLACK = ' rgb(0, 0, 0);'


function ContestApplications(props: ContestDetailsProps) {

    const dispatch: AppDispatch = useDispatch()

    const state: RootState = store.getState()

    const _applications = (state: RootState) => state.ContestReducer.applications
    const applications: IContestStories | null = useSelector(_applications)

    const [showStory, setShowStory] = useState(false);
    const [selectedContestId, setSelectedContestId] = useState<string>()
    const [selectedStory, setSelectedStory] = useState<IStoryContestOutput | null>(null)
    const [selectedAction, setSelectedAction] = useState<StoryContestStatusEnum | null>(null)
    const [feedback, setFeedback] = useState()

    useEffect(() => {
        if (props.contest && props.contest.id) {
            dispatch(fetchContesApplication(props.contest.id))
        }
    }, [])

    const { show } = useContextMenu({
        id: 'SHOW_STORY_CNTXT_MENT',
    });

    function handleContextMenu(event: any,contestId: string, story: any) {
        show({
            event,
            props: {
                key: 'value',
                contestId,
                story
            }
        })
    }

    // I'm using a single event handler for all items
    // but you don't have too :)
    const handleItemClick = ({ id, event, props }: any) => {
        switch (id) {
            case "SHOW":
                setSelectedContestId(props.contestId)
                setSelectedStory(props.story)
                setShowStory(true)
                break;
        }
    }


    const onOptionChange = (e: any) => {
        setSelectedAction(e.target.value)
    }

    const onFeedBackChange = (e: any) => {
        setFeedback(e.target.value)
    }

    const onSubmitComplete = () => {
        setSelectedAction(null)
        setSelectedContestId(undefined)
        setSelectedStory(null)
        setShowStory(false)
    }

    const showContestInfo = () => {
        onSubmitComplete()
        if(props.contest) {
            props.showContestInfo(props.contest)
        }
    }

    const submitContestApplicationStatus = async (status: StoryContestStatusEnum | null) => {
        if(status !== null) {
            const payload: ContestStoryMapUpdateRequest = {
                comments: feedback || '',
                contestId: selectedContestId || '',
                storyId: selectedStory?.id || '',
                status: status
            }

            const resp = await dispatch(submitContesApplicationStatus(payload)).unwrap()
            showSuccessToast('Succeffully updated status of applicant.')
            onSubmitComplete()
        }
    }

    return (
        <div>
            <div className='contest-info'>
                <div>
                    <h1 className='contest-name'>{props.contest?.title}</h1>
                    <div className='sl-genre'>
                        {
                            props.contest?.genres?.map(genre => <div key={`${genre.id}${genre.name}`} className='genre'>{genre.name}</div>)
                        }
                    </div>
                    <div className='mt-3'>
                        <span className='sl-key'><b>Country:</b></span>
                        <span className='sl-value'>United States</span>
                    </div>
                    <div className='mt-3'>
                        <span className='sl-key'><b>Languages:</b></span>
                        <span className='sl-value'>{props.contest?.languages?.map(language => language.name)}</span>
                    </div>
                </div>
                <div>
                    <img src={props.contest?.banner || ContestImg} className='contest-img' />
                </div>
            </div>
            <div>
                {
                    showStory === false ? <React.Fragment>
                        <span className='contest-info-title'>List Of Applicants</span>
                        <div className='contest-applicants'>
                            <table className="table table-striped table-hover">
                                <thead >
                                    <tr>
                                        <th scope="col">Applicant</th>
                                        <th scope="col">Date of Submission</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">...</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        applications && applications.stories && applications.stories.map((stroy) => <tr key={`applicant-${stroy.id}`}>
                                            <td scope="col">
                                                <img className='user-profile' src={stroy.UserProfile.profilePicture} ></img><div>{stroy.UserProfile.firstName} {stroy.UserProfile.lastName}</div></td>
                                            <td scope="col">{new Date(stroy.StoryContest.createdAt).toLocaleDateString()}</td>
                                            <td scope="col">{stroy.StoryContest.status}</td>
                                            <td scope="col"><a onClick={(evnt) => handleContextMenu(evnt,applications.id, stroy)}>...</a></td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <Menu id={'SHOW_STORY_CNTXT_MENT'}>
                                <Item id="SHOW" onClick={handleItemClick}>View Storyline</Item>
                            </Menu>
                        </div>
                    </React.Fragment> : <React.Fragment>
                        <div className='contest-info-links'>
                            <a className='link' onClick={onSubmitComplete}><MdHistory size={18} /> View Applicants</a>
                            <a className='link' onClick={showContestInfo}><ImFilesEmpty size={16} /> Contest Info</a>
                        </div>
                        <div className='contest-info-more'>
                            <Tabs
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="Grading">
                                    <div className='sl-story-info-content'>
                                        <div className='user-info'>
                                            <img src={selectedStory?.banner} className='story-banner' />
                                            <div className='story-info'>
                                                <span className='story-title'><b>{selectedStory?.title}</b></span>
                                                <span className='story-author'>Written By <b>{selectedStory?.UserProfile.firstName}</b></span>
                                                <span className='story-author-info'>{selectedStory?.UserProfile.phoneNumber || 'No Phone'} | {selectedStory?.UserProfile.email || 'No Email'}</span>
                                            </div>
                                        </div>
                                        <div className='sl-story-info'>
                                            <Accordion defaultActiveKey="0">
                                                <Card className='accordion-btn'>
                                                    <Card.Header>
                                                        <ContextAwareToggle eventKey="1"><IoIosArrowForward /> Logline </ContextAwareToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse className='accor-sl-body' eventKey="1">
                                                        <Card.Body>
                                                            <div className='story-text'>
                                                                <span className='contest-key'>{selectedStory?.logline}</span>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className='accordion-btn'>
                                                    <Card.Header>
                                                        <ContextAwareToggle eventKey="2"><IoIosArrowForward /> Synopsis</ContextAwareToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse className='accor-sl-body' eventKey="2">
                                                        <Card.Body>
                                                            <div className='story-text'>
                                                                <span className='contest-key'>{selectedStory?.synopsis}</span>
                                                            </div>

                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            <div className='sl-story-grading'>
                                                <span className='sl-grading-title'>Grade this Applicat</span>
                                                <div className='sl-selection-option'>
                                                    <div>
                                                        <input type='radio' name='grade' value='SELECTED' onChange={onOptionChange} /> <span className='sl-option-text'>Selected </span>
                                                    </div>
                                                    <span className='sl-option-info'>This option inidcates approval and elibility for further evaluation</span>
                                                </div>
                                                <div className='sl-selection-option'>
                                                    <div>
                                                        <input type='radio' name='grade' value='REJECTED' onChange={onOptionChange} /> <span className='sl-option-text'>Rejected</span> 
                                                    </div>
                                                    <span className='sl-option-info'> This option inidcates rejection due to not meeting criteria</span>
                                                </div>
                                                <div className='sl-section-comments'>
                                                    <span className='sl-grading-title'>Add Feedback</span>
                                                    <textarea onChange={onFeedBackChange} />
                                                </div>
                                                <div className='sl-section-actions'>
                                                    <Button className='cancel-button btn btn-outline-light' onClick={() => submitContestApplicationStatus('PENDING')}>Mark as Pending</Button>
                                                    <Button className='submit-button btn btn-outline-light' onClick={() => submitContestApplicationStatus(selectedAction)}>Submit</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Profile">
                                    Tab content for Profile
                                </Tab>
                                <Tab eventKey="contact" title="Experience" disabled>
                                    Tab content for Contact
                                </Tab>
                                <Tab eventKey="contact" title="Notes" disabled>
                                    Tab content for Contact
                                </Tab>
                            </Tabs>

                        </div>
                    </React.Fragment>
                }


            </div>
        </div >
    );
}

export default ContestApplications