import { createAsyncThunk } from "@reduxjs/toolkit"
import { get, post } from "../../../utils/https";
import { fileUpload } from "../../../services/file-uplaoder.service";
import { IAccountInput } from "../../../models/account.model";
import { AxiosResponse } from "axios";
import { HttpResponseType } from "../../../models/https.model";
import { showErrorToast, showSuccessToast } from "../../../services/toast.service";
import { fetchAccounts } from "../accounts/account-services";

export interface NewAccountRequestPayloadType {
   logo: File | undefined
   banner: File | undefined
   account: IAccountInput
   callback?: any
}

export const forgotyPassword = createAsyncThunk('get/forgot-password', async (payload: string, {dispatch}) => {
        const resposnse =  await get(`user-profile/forgot-password?email=${payload}`);
        return resposnse.data
     }
);

