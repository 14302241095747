import { createAsyncThunk } from "@reduxjs/toolkit"
import { post } from "../../../utils/https";
import { fileUpload } from "../../../services/file-uplaoder.service";
import { IAccountInput } from "../../../models/account.model";
import { AxiosResponse } from "axios";
import { HttpResponseType } from "../../../models/https.model";
import { showErrorToast, showSuccessToast } from "../../../services/toast.service";
import { fetchAccounts } from "../accounts/account-services";

export interface NewAccountRequestPayloadType {
   logo: File | undefined
   banner: File | undefined
   account: IAccountInput
   callback?: any
}

export const addNewAccount = createAsyncThunk('post/account', async (payload: NewAccountRequestPayloadType, {dispatch}) => {
        try {

         if(payload.logo && payload.banner) {
            return fileUpload(payload.logo, 'ACCOUNT_LOGO')
             .then((response:AxiosResponse<HttpResponseType<string>>) => {
               payload.account.logo = response.data.data
               if(payload.banner) {
                  fileUpload(payload.banner, 'ACCOUNT_BANNER')
                  .then( async (response:AxiosResponse<HttpResponseType<string>>) => {
                     payload.account.banner = response.data.data
                     try {
                        const newAccountResponse = await post('account',payload.account);
                        console.log(response.data);
                        showSuccessToast('Account Created Successfully')
                        return newAccountResponse.data;
                     }
                     catch(err: any) {
                        showErrorToast(err.response.data.message)
                        console.log(err)
                     }
                  })
               }
             })
         }

        }
        catch(err) {
           console.log(err)
        }
     }
);

