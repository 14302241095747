import { createSlice ,PayloadAction} from '@reduxjs/toolkit';
import { fetchContestList } from '../../views/pages/contest-list/contest-list.service';
import { IContestInfo } from '../../views/pages/contest-list/contest.model';
import { ICityOutput, ICountryOutput, IStateOutput } from '../../models/configurations.model';
import { fetchCities, fetchCountries, fetchStates } from './configurations.service';
import { AxiosResponse } from 'axios';
import { HttpResponseType } from '../../models/https.model';


interface IConfigurationState {
    countries: ICountryOutput[]
    states: IStateOutput[]
    cities: ICityOutput[]
}
  
  const defaultState: IConfigurationState = { 
    countries: [],
    states: [],
    cities: []
  };
  
  export const ConfigurationReducer = createSlice({
    name: 'ConfigurationReducer',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchCountries.fulfilled, (state: IConfigurationState, action: PayloadAction<HttpResponseType<ICountryOutput[]> | undefined>) => {
        state.countries = action.payload?.data || []
      })
      .addCase(fetchStates.fulfilled, (state: IConfigurationState, action: PayloadAction<HttpResponseType<IStateOutput[]> | undefined>) => {
        state.states = action.payload?.data || []
      })
      .addCase(fetchCities.fulfilled, (state: IConfigurationState, action: PayloadAction<HttpResponseType<ICityOutput[]> | undefined>) => {
        state.cities = action.payload?.data || []
      })
    },
  });
  
  export default ConfigurationReducer.reducer;