import React, { useEffect, useState } from 'react';

import YourThoughtGuard from './your-thought-guard';
import LandingImage from '../../../images/landing-page.png'
import Header from '../../../shared/components/header';
import StoryLineDetailsCarousel from './storyline-details-carousel';
import Login from '../login';

import './landing.scss'
import { AppDispatch, RootState } from '../../../redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from '../../../constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../services/firebase.service';
import { fetchUserProfile } from '../profile/profile.service';
import { User } from 'firebase/auth';
import { ToastContainer } from 'react-toastify';


function Landing() {
    const dispatch: AppDispatch = useDispatch()
    const [showLogin, setShowLogin] = useState<boolean>(false)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const response = dispatch(fetchUserProfile()).unwrap()
        response.then((res) => {
            const userProfile = res?.data
            if (userProfile) {
                if ([ROLES.SL_ADMIN, ROLES.SL_ACCOUNT_OWNER, ROLES.SL_ACCOUNT_ADMIN, ROLES.SL_ACCOUNT_USER].includes(userProfile.role.name)) {
                    navigate(location.pathname)
                }
                else {
                    auth.signOut()
                }
            }
        })
    }, [])
  
    const onLoginPress = () => {
        setShowLogin(true)
    }

    const onTryForFreePress = () => {

    }

    const onHideLogin = () => {
        setShowLogin(false)
    }

    return (
        <>
            <ToastContainer />
            <Header onLoginPress={onLoginPress} onTryForFreePress={onTryForFreePress} />
            <img src={LandingImage} className="img-fluid" alt="Responsive image"></img>

            <Login show={showLogin} onHideLogin={onHideLogin}/>

            <StoryLineDetailsCarousel />
            <YourThoughtGuard />
           
          
        </>
    )
}

export default Landing;
