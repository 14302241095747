import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../services/firebase.service";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { VoidFuntionWithOneArg } from "../../models/common.model";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../views/pages/profile/profile.service";
import { AppDispatch, RootState } from "../../redux-store";
import { User, UserCredential, createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { ROLES } from "../../constants/constants";
import { IUserProfile } from "../../models/user-profile.model";

export interface AuthProps {
  user: User
  userProfile: IUserProfile | null
  login: (email: string, password: string, onLoginError: VoidFuntionWithOneArg<any>) => Promise<void>
  logout: VoidFunction
}

const AuthContext = React.createContext<AuthProps | null>(null);


export const AuthProvider = (props: any) => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const _userProfile = (state: RootState) => state.UserProfileReducer.userProfile

  const userProfile = useSelector(_userProfile)

  const [user, setUser] = useState<any>(auth.currentUser)

  useEffect(() => {
    console.log('AUTH PROVIDER')
    const onAuthStateChangedSubsciption = auth.onAuthStateChanged((user: User | null) => {
      console.log('OAUTH SATATE  CHANGED')
      if (user) {
        setUser(user)
        const response = dispatch(fetchUserProfile()).unwrap()
        response.then((res) => {
          const userProfile = res?.data
          if (userProfile) {
            if ([ROLES.SL_ADMIN, ROLES.SL_ACCOUNT_OWNER, ROLES.SL_ACCOUNT_ADMIN, ROLES.SL_ACCOUNT_USER].includes(userProfile.role.name)) {
              navigate('/')
            }
            else {
              auth.signOut()
            }
          }
        })
      }
      else {
          navigate('/app-info') 
      }
    })

    return () => {
      onAuthStateChangedSubsciption()
    }

  }, [])


  const login = async (email: string, password: string, onLoginError: VoidFuntionWithOneArg<any>) => {

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setUser(user)
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        onLoginError(error)
      });
  }

  const createUser = (email: string, password: string) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
    // Signed up 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
  }

  const logout = async () => {
    await auth.signOut()
    setUser(null);
  };

  const value: AuthProps = useMemo(
    () => ({
      user,
      userProfile,
      login,
      logout
    }),
    [user, userProfile]
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
