import React from 'react'
import './your-thought-guard.scss'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import rectangle12432 from '../../../images/rectangle12432.png'
import rectangle2433 from '../../../images/rectangle2433.png'
import appstore from '../../../images/app-store.png'
import googleplay from '../../../images/google-play.png'
import Facebook from '../../../images/Facebook.png'
import Twitter from '../../../images/Twitter.png'
import Instagram from '../../../images/Instagram.png'


const YourThoughtGuard = () => {

    return (
        <>
            <Container className='container-your-thought-guard'  >
                <Row xs={1} md={2} className='first-row'>
                    <Col className='first-column'>
                        <b className='heading-text'>Your Thought Guard</b>
                        <Col className='second-column' >
                            <h2 className='preserving'> Preserving Privacy in Your
                                Digital Mindscape </h2>
                        </Col>
                    </Col>
                </Row>
                <Row className="second-row justify-content-md-between justify-content-lg-between">
                    <Col md={5} lg={5} sm={12}>
                        {/* 1 0f 1 */}
                        <img className='leftimage' src={rectangle12432} alt='img' />
                        <span className='para-era'>
                            In the era of constant connectivity and digital interactions, our thoughts and ideas have also found
                            their way into the digital realm. But just as we value our physical privacy, safeguarding our inner world
                            of thoughts and creativity is equally crucial.
                        </span>

                        <span className='para-storyline'>
                            <span className='textcolor'> Storyline </span> understands the sensitive nature of your thoughts and the importance of keeping them safe
                            from prying eyes or data breaches. With an unwavering commitment to privacy, the app empowers you to
                            explore your creative depths with the assurance that your inner musings remain confidential.
                        </span>
                        <span className='read-content'>Read about the privacy guidelines</span>

                    </Col>
                    <Col md={5} lg={5} sm={12}>
                        {/* 2 of 2 */}
                        <img className='rightimage' src={rectangle2433} alt='img' />
                        <span className='world-era'>

                            In a world where personal information has become a valuable commodity,
                            preserving the sanctity of our thoughts is paramount.

                        </span>
                        <span className='storyline-trusted'>
                            <span className='textcolor'> Storyline </span> is your trusted companion, allowing you to embrace your creativity,
                            explore your ideas, and foster innovation with the knowledge that your intellectual
                            privacy is safeguarded.
                        </span>
                        <span className='digital-mindspace'>
                            In the digital mindscape, choose <span className='textcolor'> Storyline </span> - where privacy
                            is paramount, and your thoughts remain exclusively yours.
                        </span>
                        <h4 className='read-more'>Read more about us </h4>
                    </Col>
                </Row>
                <Row lg={5} md={5} sl={12} xs={12} className='second-row'>
                <Col className='social-media-logos col-md-12 '>
                            <img className='google-play' src={googleplay} alt='googleplay' />

                            <img className='app-store' src={appstore} alt='appstore' />
                            <br />

                            <img className='facebook' src={Facebook} alt='facebook' />

                            <img className='twitter' src={Twitter} alt='facebook' />

                            <img className='instagram' src={Instagram} alt='facebook' />

                        </Col>

                </Row>


            </Container>

            <Row className='second-row-third-column'>
                <div className='main-landing-footer'>
                    <p className='copyright'> &copy; 2024 Storyline Inc </p>

                    <span className="anchor">
                        <a className='privacy'>  Terms</a>
                        <a className='privacy'>Privacy</a>
                    </span>
                </div>
            </Row>

        
        </>

    )
}

export default YourThoughtGuard;

// <a className='privacy' href="rep">Accessibility </a>