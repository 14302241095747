import React, { useContext, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { IoIosArrowForward } from 'react-icons/io'

// import { MdKeyboardArrowDown } from 'react-icons/md'
import Contestimage from '../../../../images/lydia-bauer.png'
import ContestImg from '../../../../images/city.jpg'
import { IContestListState } from '../../../../redux-store/contests/contest.reducer';
import { transformToAmount } from '../../../../services/transform.services';
import { ContextAwareToggle } from '../../../../shared/components/accordian-toggle';
import moment from 'moment';

interface ContestDetailsProps {
    contest: IContestListState | undefined
}


function ContestDetails(props: ContestDetailsProps) {

    console.log(props)
    const [showMore, setShowMore] = useState(false);
    const text = "The Contest That Has Launched Careers For Over 20 Years Final Draft’s Big Break Screenwriting Contest is an annual, international feature film and television screenwriting contest designed to help launch the careers of aspiring writers.  Writers who have entered Big Break have had their screenplays optioned, sold, and have secured high-profile representation. ";
    return (
        <div style={{marginBottom: 100}}>
            <div className='contest-info'>
                <div>
                    <h1 className='contest-name'>{props.contest?.title}</h1>
                    <div className='sl-genre'>
                        {
                            props.contest?.genres?.map(genre => <div key={`${genre.id}${genre.name}`} className='genre'>{genre.name}</div>)
                        }
                        {/* <div className='genre'>Sci-Fi</div>
                        <div className='genre'>Fiction</div>
                        <div className='genre'>Mystery</div> */}
                    </div>
                    <div className='mt-3'>
                        <span className='sl-key'><b>Country:</b></span>
                        <span className='sl-value'>United States</span>
                    </div>
                    <div className='mt-3'>
                        <span className='sl-key'><b>Languages:</b></span>
                        <span className='sl-value'>{props.contest?.languages?.map(language => language.name)}</span>
                    </div>
                </div>
                <div>
                    <img src={props.contest?.banner || ContestImg} className='contest-img' />
                </div>
            </div>
            <div className='contest-info-more'>
            <Accordion defaultActiveKey="0">
                <Card className='accordion-btn'>
                    <Card.Header>
                        <ContextAwareToggle className="accor-name" eventKey="0"><IoIosArrowForward /> Contest Basics</ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse className='accor-sl-body' eventKey="0">
                        <Card.Body className='card-body'>
                            <div className='contest-details'>
                            <span className='contest-key'>Contest Title:</span>
                            <span className='contest-value'>{props.contest?.title}</span>
                            </div>
                            <div className='contest-details'>
                                <span className='contest-key'>Contest Description:</span>
                                <span className='contest-value'>
                                    {showMore ? props.contest?.subject : `${(props.contest?.subject || '').substring(0, 100)}`}
                                    <button className="btn-showmore" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button>
                                </span>            
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Owner:</span>
                                <span className='contest-value'>
                                    <img className='owner-image' src={ props.contest?.account?.logo || Contestimage}  /> <span className='owner-name'>{props?.contest?.account?.name}</span>
                                </span>            
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'> Collaborators:</span>
                                <span className='contest-value'>
                                            <img src={Contestimage} alt='collaborator-img' className='collaborator-img' />
                                            <img src={Contestimage} alt='collaborator-img' className='collaborator-img' />
                                            <img src={Contestimage} alt='collaborator-img' className='collaborator-img' />
                                </span>            
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Status:</span>
                                <span className='contest-value'>
                                    {props.contest?.status}
                                </span>            
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Winners:</span>
                                <span className='contest-value'>
                                    
                                </span>            
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card className='accordion-btn'>
                    <Card.Header>
                        <ContextAwareToggle eventKey="1"><IoIosArrowForward /> Prize & Rules </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse className='accor-sl-body prize-rules' eventKey="1">
                        <Card.Body>
                             <div className='contest-details'>
                                <span className='contest-key'>First Prize:</span>
                                {props.contest?.firstPrize ? <span className='contest-value'> {props.contest?.firstPrize}</span>:<span className='contest-value'>{"N/A"}</span>}
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Second Prize:</span>
                                {props.contest?.sencondPrize ?<span className='contest-value'>{props.contest?.sencondPrize}</span> : <span className='contest-value'>{"N/A"}</span>}
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Third Prize:</span>
                                {props.contest?.thirdPrize ? <span className='contest-value'>{props.contest?.thirdPrize}</span>: <span className='contest-value'>{"N/A"}</span>}
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Rules and Eligibility:</span>
                                {props.contest?.rulesAndEligibility ? <span className='contest-value'>{props.contest?.rulesAndEligibility}</span>: <span className='contest-value'>{"N/A"}</span>}
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Entry Rules:</span>
                                {props.contest?.entryRules ? <span className='contest-value'>{props.contest?.entryRules}</span>: <span className='contest-value'>{"N/A"}</span>}
                            </div> 

                            <div className='contest-details'>
                                <span className='contest-key'>Additional Requirements:</span>
                                {props.contest?.additionalRequirements ?<span className='contest-value'>{props.contest?.additionalRequirements}</span> : <span className='contest-value'>{"N/A"}</span>}
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='accordion-btn'>
                    <Card.Header>
                        <ContextAwareToggle eventKey="2"><IoIosArrowForward /> Contest details</ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse className='accor-sl-body' eventKey="2">
                        <Card.Body>
                            <div className='contest-details'>
                                <span className='contest-key'>Contest Duration:</span>
                                <span className='contest-value'>
                                    <span>
                                        <span className='d-block'>Start Date:</span>
                                        <span className='d-block'> {moment(props.contest?.startsOn).format('DD   MMM   YYYY')}</span>  
                                    </span>
                                    <span>
                                        <span className='d-block'>End Date:</span>
                                        <span className='d-block'> {moment(props.contest?.expiresOn).format('DD   MMM   YYYY')}</span>  
                                    </span>
                                </span>
                            </div>

                            <div className='contest-details'>
                                <span className='contest-key'>Deadlines:</span>
                                <span className='contest-value'>
                                    {
                                        props.contest?.deadlines && props.contest?.deadlines.length > 0 && <React.Fragment>
                                            {
                                                props.contest?.deadlines.map((deadline, index) => <React.Fragment key={`deadline-${deadline.id}-${deadline.contestId}`}>
                                                    <span>
                                                        <span className='d-block'>Deadline {index + 1}:</span>
                                                        <span className='d-block'>{deadline.deadline}</span>  
                                                    </span>
                                                    <span>
                                                        <span className='d-block'>Fees:</span>
                                                        <span className='d-block'>{transformToAmount(deadline.fees)}</span>  
                                                    </span>
                                                </React.Fragment>)
                                            }
                                        
                                        </React.Fragment>
                                    }
                                    
                                </span>
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='accordion-btn'>
                    <Card.Header>
                        <ContextAwareToggle eventKey="3"><IoIosArrowForward /> Contact & Legal </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse className='accor-sl-body' eventKey="3">
                        <Card.Body>
                        <div className='contest-details'>
                                <span className='contest-key'>Contact Information:</span>
                                <span className='contest-value'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span >Name:</span>
                                            <span >  {props.contest?.contactName}</span>  

                                        </div>
                                        <div className='col-12'>
                                            <span >Designation:</span>
                                            <span >  {props.contest?.contactDesignation}</span>  
                                        </div>
                                        <div className='col-12'>
                                            <span >Phone number:</span>
                                            {props.contest?.contactPhoneNumber !== '+ ' ? <span >   {props.contest?.contactPhoneNumber}</span> :<span>  {"N/A"}</span> }
                                        </div>
                                        <div className='col-12'>
                                            <span >Email:</span>
                                            <span >  {props.contest?.contactEmail}</span>  
                                        </div>
                                        <div className='col-12'>
                                            <span >Linked-In profile:</span>
                                            {props.contest?.contactLinkedInProfile ?  <span >  {props.contest?.contactLinkedInProfile}</span>  : <span>  {"N/A"}</span>}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className='contest-details'>
                                <span className='contest-key'>Terms & Conditions:</span>
                                <span className='contest-value'>{props.contest?.temrsAndConditions}</span>
                            </div>
                            <div className='contest-details'>
                                <span className='contest-key'>Privacy Policy:</span>
                                <span className='contest-value'>{props.contest?.privacyPolicy}</span>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            </div>
        </div >
    );
}

export default ContestDetails