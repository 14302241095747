import moment from "moment";
import React from "react";
import { IAccountOutput } from "../../../models/account.model";

export const columns = () => {
    return [
        {
            name: 'Name',
            selector: (row: IAccountOutput) => row.name,
            sortable: true
        },
        {
            name: 'Logo',
            sortable: true,
            width: '180px',
            cell: (row: IAccountOutput) => (
                <React.Fragment>
                    {
                        <div className="mt-2 mb-2">
                            <img src={row.logo} height={50} width={50} />
                        </div>
                    }
                </React.Fragment>
            )
        },
        {
            name: 'Banner',
            width: '180px',
            cell: (row: IAccountOutput) => (
                <React.Fragment>
                    {
                        <div className="mt-2 mb-2">
                            <img src={row.banner} height={50} width={50} />
                        </div>
                    }
                </React.Fragment>

            ),
        },
        {
            name: 'Address',
            selector: (row: IAccountOutput) => row.address,
            sortable: true
        },
        {
            name: 'Created Date',
            cell: (row: IAccountOutput) => { return moment(row.createdAt).format('DD   MMM   YYYY')},
            sortable: true
        },
        {
            name: 'No. Of Users',
            selector: (row: IAccountOutput) => row.usersCount,
        },

    ]
}