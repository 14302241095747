import { Suspense, useEffect } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { AuthProvider } from "../../../hooks/providers/auth.provider";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const loader:any = useLoaderData();

  return (
    <Suspense >
      <Await
        resolve={loader.userPromise}
        errorElement={<div>Error</div>}
        children={(user) => (
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};
