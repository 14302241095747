import React, { useState, useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux-store';
import { IContestListState, clearApplications } from '../../../redux-store/contests/contest.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContestList } from './contest-list.service';
import RightSidePanel from '../../../shared/components/right-panel';
import ContestDetails from './contest-info/contest-details';
import ContestApplications from './contest-applications/contest-applications';

import DataTable from 'react-data-table-component';
import { columns } from './contest-columns';
import { PaginationQueryParams } from '../../../models/common.model';

type CONTEST_DETAILS_VIEW = 'DETAIL_VIEW' | 'APPLICATION_VIEW'

const ContestsLists = () => {

    const dispatch: AppDispatch = useDispatch();

    const _contests = (state: RootState) => state.ContestReducer.contests
    const contests: IContestListState[] = useSelector(_contests)

    const _totalContests = (state: RootState) => state.ContestReducer.totalContests
    const totalContests: number = useSelector(_totalContests)


    const [showModal, setShowModal] = useState(false);
    const [selectedContest, setSelectedContest] = useState<IContestListState>()
    const [contestDetailsView, setContestDetailsView] = useState<CONTEST_DETAILS_VIEW | null>(null)

    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);

    const getContest = async (page: number = 1, _perPage: number = 10) => {
        const pagination: PaginationQueryParams = {
            page: page,
            itemsPerPage: _perPage
        }
        setLoading(true);
        dispatch(fetchContestList(pagination)).unwrap().then(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getContest(1)
    }, [])

    const showContestInfo = (contest: IContestListState) => {
        setContestDetailsView('DETAIL_VIEW')
        setSelectedContest(contest)
        setShowModal(true)
    }

    const showApplications = (contest: IContestListState) => {
        setContestDetailsView('APPLICATION_VIEW')
        setSelectedContest(contest)
        setShowModal(true)
    }

    const onCloseModel = () => {
        setShowModal(false)
        setContestDetailsView(null)
        if (contestDetailsView === 'APPLICATION_VIEW') {
            dispatch(clearApplications())
        }
    }

    const handlePageChange = (page: number) => {
        getContest(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        getContest(page, newPerPage);
    };

    return (
        <div className="sl-list-contaner">
            {
                contests.length > 0 && <DataTable
                    columns={columns(contests, showContestInfo, showApplications)}
                    data={contests}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalContests}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    paginationPerPage={perPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            }
            
            <RightSidePanel showModal={showModal} setShowModal={setShowModal} onClose={onCloseModel} >
                {contestDetailsView === 'DETAIL_VIEW' && <ContestDetails contest={selectedContest} />}
                {contestDetailsView === 'APPLICATION_VIEW' && <ContestApplications contest={selectedContest} showContestInfo={showContestInfo} />}
            </RightSidePanel>
           
        </div>
    );
}

export default ContestsLists;