import { IconType } from 'react-icons';
import { Bs1CircleFill, Bs2Circle, Bs2CircleFill, Bs3Circle, Bs3CircleFill, Bs4Circle, Bs4CircleFill, BsCheck, BsCheckCircleFill, BsPlus } from 'react-icons/bs';
import { VoidFuntionWithOneArg } from '../../../models/common.model';
import React from 'react';
import { Step } from '../../models/steppers.model';
import './styles.scss';


interface StepperBreadCrumbProps {
    steps: Step[]
    currentStep: Step
    onClickStep: VoidFuntionWithOneArg<Step>
}

export const StepperBreadCrumb = (props: StepperBreadCrumbProps) => {

    return (
        <div className='sl-stepper-bread-crumb'>
            {
                props.steps.map((step, index: number) => {
                    if(step.title === "Review And Submit"){ return null;}
                    return <React.Fragment>
                        <button
                            key={index}
                            style={{ color: props.currentStep.title === step.title ? 'black' : 'grey' }}
                            className='step-btn'
                            onClick={() => {if(props.currentStep.step >= step.step) {props.onClickStep(step)}}}>
                            {props.currentStep.title === step.title ? (<span className='step-circle'>{step.step}</span>) : (props.currentStep.step < step.step ? <span className='step-nobg-circle'>{step.step}</span>  : <BsCheckCircleFill className='b2check-circle-fill'/>)} {step.title}
                        </button>
                        {
                            index < props.steps.length - 2 && <hr className='step-border' />
                        }
                    </React.Fragment>
                })
            }
        </div>
    )
}

/*import { IconType } from 'react-icons';
import { Bs1CircleFill, Bs2Circle, Bs2CircleFill, Bs3Circle, Bs3CircleFill, Bs4Circle, Bs4CircleFill, BsCheck, BsCheckCircleFill, BsPlus } from 'react-icons/bs';
import { VoidFuntionWithOneArg } from '../../../models/common.model';
import React from 'react';
import { Step } from '../../models/steppers.model';
import './styles.scss';


interface StepperBreadCrumbProps {
    steps: Step[]
    currentStep: Step
    onClickStep: VoidFuntionWithOneArg<Step>
}

export const StepperBreadCrumb = (props: StepperBreadCrumbProps) => {

    return (
        <div className='sl-stepper-bread-crumb'>
            {
                props.steps.map((step, index: number) => {
                    if(step.title === "NA"){ return null;}
                    return <React.Fragment>
                        <button
                            key={index}
                            style={{ color: props.currentStep.title === step.title ? 'black' : 'grey' }}
                            className='step-btn'
                            onClick={() => props.onClickStep(step)}>
                            {props.currentStep.title === step.title ? (<span className='step-circle'>{index + 1}</span>) : (<BsCheckCircleFill className='b2check-circle-fill' />)} {step.title}
                        </button>
                        {
                            index < props.steps.length - 2 && <hr className='step-border' />
                        }
                    </React.Fragment>
                })
            }
        </div>
    )
}*/