import { useContext } from "react";
import { useAccordionButton } from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';


const BLACK = ' rgb(0, 0, 0);'

export function ContextAwareToggle({ children, eventKey, callback }: any) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <span
            style={{ backgroundColor: isCurrentEventKey ? BLACK : BLACK }}
            onClick={decoratedOnClick}
        >
            {children}
        </span>
    );
}
