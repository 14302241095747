import { Button } from "react-bootstrap"
import { BsCheck } from "react-icons/bs"
import { Step } from "../../../shared/models/steppers.model"
import { VoidFuntion, VoidFuntionWithOneArg } from "../../../models/common.model"

export interface StepperFooterButtonProps {    
    onClickContinue: VoidFuntionWithOneArg<Step>
    onClickCancel: VoidFuntionWithOneArg<Step>
    currentStep: Step
    totalNoOfSteps: number
}


export const StepperFooterButtons = (props: StepperFooterButtonProps)=> {
    
    return(
    <div className='footer-row'>
              <div className='save-img-txt-row'>
                {/* <BsCheck className='save-img'/>
                <span className='save-txt'>saved just now</span> */}
              </div>
             <div className='save-img-txt-row'>
                <Button variant='outline-light' type='submit' className='cancel-button' onClick={() => props.onClickCancel(props.currentStep)}>Cancel</Button>
                <Button variant='outline-light' type='submit' onClick={() => props.onClickContinue(props.currentStep)} className={props.currentStep.step === props.totalNoOfSteps ? 'review-submit' :'submit-button'}>{props.currentStep.step === props.totalNoOfSteps ? 'Review & Submit' : 'Continue'}</Button>
             </div>
           </div>
)}