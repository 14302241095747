import { Button, Col, Dropdown, DropdownButton, Form, FormControl, Image, ModalDialog, Row } from "react-bootstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"
// import { Bs1CircleFill, Bs2CircleFill, Bs3Circle, Bs3CircleFill, Bs4Circle, Bs4CircleFill, BsCheck, BsCheckCircleFill, BsPlus } from 'react-icons/bs';
import './styles.scss'
import { Footer } from "../contest/footer"
import { AppDispatch, RootState } from "../../../redux-store";
import { useDispatch, useSelector } from "react-redux";
import { GrAdd, GrFilter } from "react-icons/gr";
import NoData from "../../../shared/components/no-data";


interface footerProps {

    onClickContinue?: () => void
    onClickCancel?: () => void
    currentStep?: number
}

const Users = (props: footerProps) => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const _accounts = (state: RootState) => state.AccountsReducer.accounts
    const accounts = useSelector(_accounts)


    useEffect(() => {
        // dispatch(fetchAccounts())
    }, [])

    const onAddUser = () => {
        navigate('/new-user')
    }


    return (
        <div className='search-container'>
            <div className="row sl-search-container">
                <div className="col-12 col-md-10 ">
                    <Form.Control type="search" placeholder="Search contests, applicants" />
                </div>
               
                <div className="col-12 col-md-2">
                    <Button variant="primary">Search</Button>
                </div>

                
            </div>

            <div className="row">
                <div className='col-12 col-md-12'>
                    <div className="sl-welcome-note">
                        <span className='sl-title'>Users</span>
                        <span className='sl-sub-text'> list of all users created under this accounts.</span>
                    </div>
                </div>
            </div>

            <div className='new-btn-container'>
                <div className='sl-new-contest'>
                    <button className='new-item-btn' onClick={onAddUser}> <GrAdd></GrAdd> Add User </button>

                </div>
            </div>
            <div className='sl-filter'>
                <GrFilter></GrFilter> Filter
            </div>
            <hr />
            <div className="sl-list-contaner">
            <table className="table table-striped table-hover">
                <thead >
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Logo</th>
                        <th scope="col">Banner</th>
                        <th scope="col">Address</th>
                        <th scope="col">No. Of Users</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        accounts.map((account, index) => {
                            return (
                                <tr key={index}>
                                    <td scope="col">{account.name}</td>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                    <td scope="col">{account.address}</td>
                                    <td scope="col">{account.usersCount || 1}</td>

                                </tr>

                            )
                        })

                    }
                </tbody>
            </table>
            {
                accounts.length === 0 && <NoData />
            }

            </div>

        </div>
    )
}

export default Users