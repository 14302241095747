import React from 'react';

import { MdPersonAddAlt1 } from 'react-icons/md'
import { Carousel } from 'react-bootstrap';

import writerImg from '../../../images/landing-writer.png'
import seekerImg from '../../../images/landing-seeker.png'

import './storyline-details-carousel.scss'
import { FaFeatherAlt } from 'react-icons/fa';

const StoryLineDetailsCarousel = () => {
    return (
        <div className='sl-detail-carousel'>
            <Carousel>
                <Carousel.Item interval={1000}>
                    <div className="container text-center">
                        <div className="row">
                            <div className="col">
                                <div className="sl-platform-type">
                                    <div className='button-class'>
                                        <span className='icon'><FaFeatherAlt /></span>

                                        <h3 className="sl-type">Writers</h3>
                                    </div>
                                </div>
                                <div className='sl-carousel-img'>
                                    <img src={writerImg} className='img-fluid sl-landing-carousel-img' />
                                </div>
                            </div>
                            <div className="col">
                                <div className='sl-platform'>
                                    <div className='sl-platform-info'>
                                        <span className='d-block'><b>Watch How Our</b></span>
                                        <span className='d-block'><b>Platform Works!!</b></span>
                                    </div>

                                    <div className="sl-platform-more-box">

                                        <div className="title"><MdPersonAddAlt1 /> Create a contest</div>
                                        <p className='info'>
                                            Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.
                                        </p>
                                    </div>

                                    <div className="sl-platform-more-box">

                                        <div className="title"> <MdPersonAddAlt1 />Discover the Existing Ongoing Contest</div>
                                        <p className='info'>Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>

                                    <div className="sl-platform-more-box">
                                        <div className="title"> <MdPersonAddAlt1 />Secure your slot: Enroll in time</div>
                                        <p className='info'> Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>
                                    <div className="sl-platform-more-box">
                                        <div className="title"> <MdPersonAddAlt1 />Meet with the Managers and Producers</div>
                                        <p className='info'>Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Carousel.Item>
                <Carousel.Item>

                <div className="container text-center">
                        <div className="row">
                            <div className="col">
                                <div className="sl-platform-type">
                                    <div className='button-class'>
                                        <span className='icon'><FaFeatherAlt /></span>
                                        <h3 className="sl-type">Seekers</h3>
                                    </div>
                                </div>
                                <div className='sl-carousel-img'>
                                    <img src={seekerImg} className='img-fluid sl-landing-carousel-img' />
                                </div>
                            </div>
                            <div className="col">
                                <div className='sl-platform'>
                                    <div className='sl-platform-info'>
                                        <span className='d-block'><b>Watch How Our</b></span>
                                        <span className='d-block'><b>Platform Works!!</b></span>
                                    </div>

                                    <div className="sl-platform-more-box">

                                        <div className="title"><MdPersonAddAlt1 /> Create a contest</div>
                                        <p className='info'>
                                            Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.
                                        </p>
                                    </div>

                                    <div className="sl-platform-more-box">

                                        <div className="title"> <MdPersonAddAlt1 /> Uploads the rules and Pricing</div>
                                        <p className='info'>Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>

                                    <div className="sl-platform-more-box">
                                        <div className="title"> <MdPersonAddAlt1 /> Track and Grade the applicants </div>
                                        <p className='info'> Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>
                                    <div className="sl-platform-more-box">
                                        <div className="title"> <MdPersonAddAlt1 /> Publish the results</div>
                                        <p className='info'>Lorem ipsum text is a dummy text Lorem ipsum
                                            text is a dummy text Lorem ipsum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </Carousel.Item>
            </Carousel>
        </div>

    );
};

export default StoryLineDetailsCarousel;
