import { createAsyncThunk } from "@reduxjs/toolkit"
import { get, post } from "../../../utils/https"
import { HttpResponseType } from "../../../models/https.model"
import { IUserProfile } from "../../../models/user-profile.model"
import { AxiosResponse } from "axios"

export const saveUserPreferences = createAsyncThunk('post/user-preferences', async (payload: any, thunkAPi) => {
    try {
        const response = await post('user-profile/user-preferences', payload)
        return response.data    
    }
    catch(err) {
        console.log(err)
    }
})

export const fetchUserProfile = createAsyncThunk('get/user-profile', async() => {
    try {
        const response: AxiosResponse<HttpResponseType<IUserProfile>> = await get('user-profile')
        return response.data    
    }
    catch(err) {
        console.log(err)
    }
})

export const saveLoggedInUserProfile = createAsyncThunk('post/user-profile', async() => {
    try {
        const response: AxiosResponse<HttpResponseType<IUserProfile>> = await post('user-profile', {
            role: ''
        })

        return response.data    
    }
    catch(err) {
        console.log(err)
    }
})