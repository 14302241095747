import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContestInfo } from '../../views/pages/contest-list/contest.model';
import { IAccountOutput } from '../../models/account.model';
import { fetchAccounts } from '../../views/pages/accounts/account-services';
import { addNewAccount } from '../../views/pages/new-account/account-services';

export interface IAccountsListState extends IContestInfo { }

interface IAccountsState {
  accounts: IAccountOutput[]
  totalAccounts: number
}

const defaultState: IAccountsState = {
  accounts: [],
  totalAccounts: 0
};

export const AccountsReducer = createSlice({
  name: 'AccountsReducer',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchAccounts.fulfilled, (state: IAccountsState, action: PayloadAction<any>) => {
        state.accounts = action.payload.rows || []
        state.totalAccounts = action.payload.count
    })
    .addCase(addNewAccount.fulfilled, (state: IAccountsState, action: PayloadAction<any>) => {
      if(action.payload?.status === 200) {
      }
    })
  },
});

export default AccountsReducer.reducer;