// import './style.scss'
import { Image } from 'react-bootstrap';
import editForm from '../../../images/edit-line.png';
import { FaChevronUp,FaChevronDown} from 'react-icons/fa';


interface headerProps {
   
    currentStep?: number
    onEditClick?: ()=>void
    onClick?: ()=> void
    showContent?: boolean
    queryNumber: number
    queryHeading: string
    querySubHeading: string

}

export const Header = (props: headerProps)=>{

return(

<div>
<h4 className='step-header' >Setup new User</h4>
        
   <div className="step-details-info" >
       <div className='step-details-title' >
         <div className='step-details-number'>
            <span className='question-one'>{props.queryNumber}</span>
         </div>
         <div>
            <span className='step-title'>{props.queryHeading}</span>
            <span className='step-sub-text'> {props.querySubHeading}</span>
         </div>
       </div>

       <div>
         <button onClick={props.onClick} className='chevron-bg'>
          { props.showContent ? <FaChevronDown  className='chevron'/> : <FaChevronUp  className='chevron'/>}
         </button>
       </div>
  
   </div>
</div>
   )}