import { useEffect } from "react";
import './style.scss'
import { AiOutlineClose } from "react-icons/ai";

const RightSidePanel = (props: any) => {
    const { showModal, onClose } = props;

    useEffect(() => {
        // call click outside
    }, []);

    return (
        <div
            className={showModal ? "right-panel-dialog show" : "right-panel-dialog"}
            role="document"
        >
            <div className="right-panel-content">
                <div className="right-panel-body">
                    <div>
                        <div className="close-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    onClose()
                                }}
                            >
                            <AiOutlineClose />
                            </button></div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightSidePanel