import { configureStore } from '@reduxjs/toolkit'

import ContestReducer from './contests/contest.reducer'
import  UserPreferencesReducer  from './user-preferences/user-preferences.redux'
import UserProfileReducer from './user-profile/user-profile.redux'
import AccountsReducer from './accounts/accounts.reducer'
import ConfigurationReducer from './configurations/configurations.reducer'

export const store = configureStore({
  reducer: {
    ContestReducer,
    UserPreferencesReducer,
    UserProfileReducer,
    AccountsReducer,
    ConfigurationReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch