import { AiOutlineFundView, AiOutlineHome } from 'react-icons/ai'
import './style.scss'
import { ImPower } from 'react-icons/im'
import { BsGraphUpArrow } from 'react-icons/bs'
import { CiSettings, CiUser } from 'react-icons/ci'
import { VoidFuntion } from '../../../models/common.model'
import { User } from 'firebase/auth'
import { IUserProfile } from '../../../models/user-profile.model'
import { isSLAdmin } from '../../../utils/app-utils'
import { IAccountOutput } from '../../../models/account.model'

interface LeftNavProps {
    logout: VoidFuntion
    user: User
    userProfile: IUserProfile
}

const LeftNav = (props: LeftNavProps) => {

    return <div className="sl-left-nav d-none d-lg-block">
        {
        !isSLAdmin(props.userProfile.role.name) && <div className='account-banner'>
            <img src={props.userProfile?.account?.banner || 'https://e0.pxfuel.com/wallpapers/936/807/desktop-wallpaper-man-made-chicago-usa-city-reflection-building-skyscraper.jpg'} alt='Account Banner' />
        </div>
        }
        <div className={`account ${isSLAdmin(props.userProfile.role.name) && 'mt-3'}`}>
            <div className='account-logo'>
                <img src={props.userProfile?.account?.logo || 'https://scriptmag.com/.image/t_share/MTY3Mzc4ODUwNzk2MTUyNDMy/image-placeholder-title.png'} alt='Account Logo' />
            </div>
            <div className='account-details'>
                <span className='account-name'>{props.userProfile.account.name}</span>
                {/* <span className='account-members'>0 members</span> */}
            </div>
        </div>

        <ul className="nav flex-column sl-v-nav">
            <li className="nav-item sl-v-nav-item active">
                <AiOutlineHome />
                <a className="nav-link active" href="#/">Home</a>
            </li>
            <li className="nav-item sl-v-nav-item">
                <AiOutlineFundView />
                <a className="nav-link" href="#/contest-list">Contests</a>
            </li>
            <li className="nav-item sl-v-nav-item">
                <ImPower /> 
                <a className="nav-link" href="#/profile-summary">Profile</a>
            </li>
            <li className="nav-item sl-v-nav-item">
                <BsGraphUpArrow /> 
                <a className="nav-link">Analytics</a>
            </li>
            {
                props.userProfile.role.name === 'SL_ADMIN' && <li className="nav-item">
                <CiSettings />
                <a className="nav-link" href="#/accounts">Accounts</a>
            </li>
            }
            {
                ['SL_ACCOUNT_OWNER', 'SL_ACCOUNT_ADMIN'].includes(props.userProfile.role.name) && <li className="nav-item">
                <CiUser />
                <a className="nav-link" href="#/users">Users</a>
            </li>
            }
            <li className="nav-item">
                <CiSettings />
                <a className="nav-link hover" onClick={props.logout}>Logout</a>
            </li>
        </ul>
    </div>
}

export default LeftNav