import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../utils/https";

export const fetchGenresList = createAsyncThunk('get/genre', async () => {
        try{
        const response = await get('genre');
        return response.data;
        }
        catch(err) {
        console.log(err)
        }
     }
  );