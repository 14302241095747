import './style.scss'
import { Button, Image } from 'react-bootstrap';
import editForm from '../../../images/edit-line.png';
import { FaChevronUp,FaChevronDown} from 'react-icons/fa';

interface headerProps {
   
    currentStep?: number
    onEditClick?: ()=>void
    onClick?: ()=> void
    showContent?: boolean
    queryNumber: number
    queryHeading: string
    querySubHeading: string

}

export const Header = (props: headerProps)=>{

return(

<div>
<h4 className='contest-heading'>Create a Contest </h4>
        
        { props.currentStep === 5 &&
        
        <div className='summary-row'>
          <div>
            <span className='contest-basics'>Summary</span>
            <span className='contest-sub-text'>Verify your entry details before submission</span>
            </div>
            <div>
              <button onClick={props.onEditClick} className='chevron-bg'>
                   { <Image src={editForm} className='chevron'/> }
              </button>
          </div>
         
        </div>
         }
   <div className="contest-one">
       
       <div className='question-title-row'>
         <div className='question-one-bg'>
            <span className='question-one'>{props.queryNumber}</span>
         </div>
         <div>
            <span className='contest-basics'>{props.queryHeading}</span>
            <span className='contest-sub-text'> {props.querySubHeading}</span>
         </div>
       </div>

       <div>
         <button onClick={props.onClick} className='chevron-bg'>
          { props.showContent ? <FaChevronDown  className='chevron'/> : <FaChevronUp  className='chevron'/>}
         </button>
       </div>
  
   </div>
</div>
   )}