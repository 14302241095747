import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { UserCredential } from 'firebase/auth'

import { AiOutlineClose } from 'react-icons/ai';

import Maskgroup from '../../../images/maskgroup.png';
import Saly from '../../../images/Saly.png';

import './login-popup.scss'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/providers/auth.provider';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux-store';
import { forgotyPassword } from './user.services';
import { showErrorToast, showSuccessToast } from '../../../services/toast.service';

// import SignUp from '../../pages/signup/signup-modal'


interface LoginProps {
    show: boolean
    onHideLogin?: VoidFunction
}

const validateEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validatePassword = (password: string) => {
    return password.length >= 8;
  };
  


function Login(props: LoginProps = { show: false }) {

    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch()
    const _auth = useAuth()

    const [loginEmail, setLoginEmail] = useState("")
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [erroremail, setErrorEmail] = useState('');
    const [errorpassword, setErrorPassword] = useState('');
    const [user, setUser] = useState<any>({})
    const [showForgotPassword, setShowForgotPassword] = useState(false)

    const onLoginError = (error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
    }

    const onLoginSuccess = (userCredential: UserCredential) => {
        setUser(user)
    }

    const onLogin = async () => {

        const emailIsValid = validateEmail(loginEmail);
        const passwordIsValid = validatePassword(loginPassword);

        if (!emailIsValid) {
            // Set an error message for the email input
            
            return setErrorEmail('Email is not valid.');
        }

        if (!passwordIsValid) {
            // Set an error message for the password input

            return setErrorPassword('Password must be at least 8 characters long.');
        }

        _auth?.login(loginEmail, loginPassword, onLoginError)

    }

    const handleKeyDown = (event: any) => {
        console.log(event)
        if (event.key === 'Enter') {
            onLogin()
        }
      }


    const closeLoginModal = () => {
        if(props.onHideLogin) props.onHideLogin()
    }

    const onForgetClick = () => {
        setShowForgotPassword(true)
    }

    const onHideForgotPassword = () => {
        setShowForgotPassword(false)
        setForgotPasswordEmail("")
    }

    const onForgotPassword = () => {
        dispatch(forgotyPassword(forgotPasswordEmail))
        .unwrap()
        .then(res => {
            showSuccessToast('Email sent successfully. Please check your email for further details.')
            onHideForgotPassword()
        })
        .catch(err => {
            showErrorToast('Unable to send email to given email address. Please check your email and try again!')
        })
    }


    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='model-body'>
                <a onClick={closeLoginModal} className='close-icon'>
                    <AiOutlineClose />
                </a>
                <div className='login-modal'>
                    <div className='login-img d-none d-lg-block'>
                        <img className='img-fluid login-bg-img' src={Maskgroup} alt='Login Image' />
                    </div>
                    {
                        showForgotPassword === false && <div className='login-container'>
                        <h3 className='greet'><b>Hello there</b> <span>👋</span></h3>
                        <p className='info'> Please enter your email address and password to continue.</p>
                        <label className='label' typeof='email'>Company Email Address </label>
                        <br />
                        <input className='input' type='email' placeholder='Sample@domain.com'
                            onChange={(event) => { setLoginEmail(event.target.value) }}
                        />
                        <br />
                        <label className='label' typeof='password'>Password</label>
                        <br />
                        <input className='input' type='password' placeholder='Password' onKeyDown={handleKeyDown}
                            onChange={(event) => { setLoginPassword(event.target.value) }}
                        />
                        <a className='forget-password hover' onClick={onForgetClick}>Forget Password?</a>
                        {/* <div className='terms'>
                            <p className='temrs-privacy'>By signing up, you agree to the<a href='replace'>
                                Terms of Service</a>  and <a href='replace'> Privacy Policy </a>,
                                Including <a href='replace'> Cookie Use.</a>  Storyline may use your contact
                                information, including email address for purpose es outlined in our Privacy Policy, like keeping your account secure and personalizing our services, including ads. 
                                <a href='replace'> Learn more.</a> Others will be able to find you by email or phone number, when provided, unless you choose otherwise<a href='replace'> here.</a>  
                            </p>
                        </div> */}
                        
                        <div className='btn-container'>
                            <button className='login-btn m-2' onClick={onLogin}>Log in</button>
                            <button className='signup-btn m-2' onClick={()=>navigate('/SignUp')}>Sign up</button>
                        </div>
                    </div>
                    }
                    {
                        showForgotPassword === true &&  <div className='login-container'>
                        <h3 className='greet'><b>Hello there</b> <span>👋</span></h3>
                        <p className='info'> Please enter your email address  to continue.</p>
                        <label className='label' typeof='email'>Company Email Address </label>
                        <br />
                        <input className='input' type='email' placeholder='Sample@domain.com'
                            onChange={(event) => { setForgotPasswordEmail(event.target.value) }}
                        />
                        <br />
                        <a className='forget-password hover' onClick={onHideForgotPassword}>Login</a>
                        {/* <div className='terms'>
                            <p className='temrs-privacy'>By signing up, you agree to the<a href='replace'>
                                Terms of Service</a>  and <a href='replace'> Privacy Policy </a>,
                                Including <a href='replace'> Cookie Use.</a>  Storyline may use your contact
                                information, including email address for purpose es outlined in our Privacy Policy, like keeping your account secure and personalizing our services, including ads. 
                                <a href='replace'> Learn more.</a> Others will be able to find you by email or phone number, when provided, unless you choose otherwise<a href='replace'> here.</a>  
                            </p>
                        </div> */}
                        
                        <div className='btn-container'>
                            <button className='login-btn m-2' onClick={onForgotPassword}>Continue</button>
                            <button className='signup-btn m-2' onClick={()=>navigate('/SignUp')}>Sign up</button>
                        </div>
                    </div>
                    }
                    
                </div>
                <img className='corner-image' src={Saly} alt='cornerimage' />
            </Modal.Body>

        </Modal>
    );
}



export default Login;