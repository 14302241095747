import './style.scss'
import { Button, Image } from 'react-bootstrap'
import Wallpaper from '../../../images/wallpaper-image.png'
import Logo from '../../../images/account-logo.png'
import facebookLogo from '../../../images/facebookLogo.png'
import linkedInLogo from '../../../images/linkedInLogo.png'
import twitterLogo from '../../../images/twitterLogo.png'
import { useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { BsPencilFill, BsQuestionCircle } from 'react-icons/bs'
import profile from '../../../images/profile.png'
import contactProfile from '../../../images/contactProfile.png'

const ProfileSummary=() =>{

  const [isEditing, setIsEditing] = useState(false);
  
  const [image, setImage] = useState(sessionStorage.getItem('image') || Wallpaper); 
  const [logo, setLogo] = useState(sessionStorage.getItem('logo') || Logo); // Get image from session storage
  const [editedImage, setEditedImage] = useState(image); // Edited image state
  const [editedLogo, setEditedLogo] = useState(logo);
  
  const [profileImg, setProfileImg] = useState(sessionStorage.getItem('profileImg') || profile);
  const [editedProfileImg, setEditedProfileImg] = useState(profileImg);
  const [cProfileImg , setCProfileImg] = useState(sessionStorage.getItem('cProfileImg') || contactProfile);
  const [editedCProfileImg , setEditedCProfileImg] = useState(cProfileImg);
  
  const [companyName, setCompanyName] = useState(sessionStorage.getItem('companyName') || 'Final Draft'); 
  const [companyAddress, setCompanyAddress] =  useState(sessionStorage.getItem('companyAddress') || '6600 Sunset Blvd Los Angles,CA 90028'); 
  const [userName, setUserName] = useState(sessionStorage.getItem('userName') || 'Ryan Lee');
  const [userPhone, setUserPhone] =  useState(sessionStorage.getItem('userPhone') || '1234567890'); 
  const [contactName, setContactName] = useState(sessionStorage.getItem('contactName') || 'John Smith');
  const [contactEmail, setContactEmail] = useState(sessionStorage.getItem('contactEmail') || 'jsmith@email.com');
  const [contactLinkedIn, setContactLinkedIn] = useState(sessionStorage.getItem('contactLinkedIn') || 'linkedin.com/dummyprofile');
  const [contactIndeed, setContactIndeed] = useState(sessionStorage.getItem('contactLinkedIn') || 'indeed.com/contestlisting');
  const [contactTwitter, setContactTwitter] = useState(sessionStorage.getItem('contactLinkedIn') || 'twitter.com/dummyaccount');
  const [contactOtherUrl, setContactOtherUrl] = useState(sessionStorage.getItem('contactLinkedIn') || 'www.finaldraft.com');
  
  //inline changes
  const [inlineUserName, setInlineUserName] = useState(false);
  const [inlineUserPhone, setInlineUserPhone] = useState(false);
  
  const handleInlineUserName =()=> {
   
     setInlineUserName(!inlineUserName);

  }
  const handleInlineUserPhone =()=> {
   
     setInlineUserPhone(!inlineUserPhone);
 }

 const handleInlineChanges =() =>{

     const inlineUserNameChange = document.getElementById("inline-user-name-change")?.textContent;
     const inlineUserPhoneChange = document.getElementById("inline-user-phone-change")?.textContent;
     if(inlineUserNameChange){
     setUserName(inlineUserNameChange);
     sessionStorage.setItem('userName',inlineUserNameChange);}
     else if(inlineUserPhoneChange)
     {
      setUserName(inlineUserPhoneChange);
      sessionStorage.setItem('userPhone',inlineUserPhoneChange);
     }
     
 
    }


  const handleImageChange = (e: any) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setEditedImage(newImage); // Set edited image
  };
  const handleLogoChange = (e: any) => {
    const newLogo = URL.createObjectURL(e.target.files[0]);
    setEditedLogo(newLogo); // Set edited image
  };

  const handleProfileImgChange =(e: any)=>{
   
    const newProfileImg = URL.createObjectURL(e.target.files[0]);
    setEditedProfileImg(newProfileImg);

  }
  const handleCProfileImgChange =(e: any)=>{
    
    const newCProfileImg = URL.createObjectURL(e.target.files[0]);
    setEditedCProfileImg(newCProfileImg);

  }

  

  const handleTextSave = () => {

    const editedCompanyName = document.getElementById("org-name")?.textContent || companyName;
    const editedCompanyAddress = document.getElementById("org-address")?.textContent || companyAddress;
    const editedUserName = document.getElementById("user-name")?.textContent || userName;
    const editedUserPhone = document.getElementById("user-phone")?.textContent || userPhone;
    const editedContactName = document.getElementById("contact-name")?.textContent || contactName;
    const editedContactEmail = document.getElementById("contact-email")?.textContent || contactEmail;
    const editedContactLinkedIn = document.getElementById("contact-linked-in")?.textContent || '';
    const editedContactIndeed = document.getElementById("contact-indeed")?.textContent || '';
    const editedContactTwitter = document.getElementById("contact-twitter")?.textContent || '';
    const editedContactOtherUrl = document.getElementById("contact-other-url")?.textContent || '';


    setCompanyName(editedCompanyName);
    setCompanyAddress(editedCompanyAddress);
    setUserName(editedUserName);
    setUserPhone(editedUserPhone);
    setContactName(editedContactName);
    setContactEmail(editedContactEmail);
    setContactLinkedIn(editedContactLinkedIn);
    setContactIndeed(editedContactIndeed);
    setContactTwitter(editedContactTwitter);
    setContactOtherUrl(editedContactOtherUrl);
    sessionStorage.setItem('companyName',editedCompanyName);
    sessionStorage.setItem('companyAddress',editedCompanyAddress);
    sessionStorage.setItem('userName',editedUserName);
    sessionStorage.setItem('userPhone',editedUserPhone);
    sessionStorage.setItem('contactName',contactName);
    sessionStorage.setItem('contactEmail',contactEmail);
    sessionStorage.setItem('contactLinkedIn',contactLinkedIn);
    sessionStorage.setItem('contactIndeed',contactIndeed);
    sessionStorage.setItem('contactTwitter',contactTwitter);
    sessionStorage.setItem('contactOtherUrl',contactOtherUrl);

  };

  const handleSave = () => {
    setIsEditing(false);
    if (editedImage || editedLogo || companyName || companyAddress || editedProfileImg || editedCProfileImg) {
      setImage(editedImage); 
      sessionStorage.setItem('image', editedImage); // Store image in session storage

      setLogo(editedLogo);
      sessionStorage.setItem('logo', editedLogo);

      setProfileImg(editedProfileImg);
      sessionStorage.setItem('profileImg', editedProfileImg);

      setCProfileImg(editedCProfileImg);
      sessionStorage.setItem('cProfileImg', editedCProfileImg);


      handleTextSave();

    }
    else{
      alert('please enter Organisation Name and Address')
    }
    
  };



  const handleCancel = () => {
    setIsEditing(false);
  };

  




    return(
        <div className='container'>
         <h4 className='profile-summary'>Profile Summary</h4>
         
      {isEditing && (
        
        <div className='edit-container'>
          
                <div className='edit-images-container'>
                       <h5><b>Wallpaper :</b></h5><br></br>
                       <input type="file" onChange={handleImageChange}/><br></br>
                       <br></br><AvatarEditor
                           image={editedImage? editedImage : image}
                           width={970}
                           height={190}
                           //border={50}
                           //color={[255, 255, 255, 0.6]} // RGBA
                           scale={1.2}
                           rotate={0}
                        />
                 </div>
                 
               <br></br>
         
                <div className='edit-images-container'>
                        <h5><b>Logo :</b></h5><br></br>
                        <input type="file" onChange={handleLogoChange} />
                        <AvatarEditor
                            image={editedLogo? editedLogo: logo}
                            width={140}
                            height={140}
                            //border={50}
                            //color={[255, 255, 255, 0.6]} // RGBA
                            scale={1.2}
                            rotate={0}
                        />
                 </div>
              
                <br></br>
           
                <label>Organization Name:</label><div id="org-name" className='edit-text-box' contentEditable={true} >{companyName}</div>
                <br></br>
                <label>Organization Address:</label> <div  id="org-address" className='edit-text-box' contentEditable={true}>{companyAddress}</div>
                <br></br><br></br>
           
          
          <h5><b>User Profile</b></h5>
          <div className='edit-user-profile '>
           
                 <div className='edit-images-container'>
                     <label><b>Profile Image :</b></label><br></br>
                     <input type="file" onChange={handleProfileImgChange}/>
                     <AvatarEditor
                          image={editedProfileImg? editedProfileImg : profileImg}
                          width={100}
                          height={100}
                          borderRadius={50}
                          //border={100
                          //borderRadius={200}
                          //color={[255, 255, 255, 0.6]} // RGBA
                          scale={1.2}
                          rotate={0}
                         />
                 </div>
          
          <br></br>
          
                 <br></br><label>Name:</label> <div id="user-name" className='edit-text-box'  contentEditable={true}>{userName}</div>
                 <br></br><label>Email: (can't change email)</label> <div id="user-name" className='edit-text-box'>ryan.lee@firstdraft.com</div> 
                 <br></br><label>Phone Number:</label> <div id="user-phone" className='edit-text-box' contentEditable={true}>{userPhone}</div>

                 <br></br>
          </div>
          
          <br></br><br></br>
          <h5><b>Company Contact</b></h5>
          <div className='edit-user-profile '>
                
                <div className='edit-images-container'>
                      <label><b>Contact Profile Image :</b></label><br></br>
                      <input type="file" onChange={handleCProfileImgChange}/>
                     <AvatarEditor
                       image={editedCProfileImg? editedCProfileImg : cProfileImg}
                       width={100}
                       height={100}
                       borderRadius={50}
                       //border={100
                       //borderRadius={200}
                      //color={[255, 255, 255, 0.6]} // RGBA
                      scale={1.2}
                      rotate={0}
                    />
               </div>
               <br></br><label>Contact Name:</label> <div id="contact-name" className='edit-text-box'  contentEditable={true}>{contactName}</div>
                <br></br><label>Contact Email:</label> <div id="contact-email" className='edit-text-box' contentEditable={true}>{contactEmail}</div>
                <br></br><label>LinkedIn URL:</label> <div id="contact-linked-in" className='edit-text-box' contentEditable={true}>{contactLinkedIn}</div>
                <br></br><label>Indeed URL:</label> <div id="contact-indeed" className='edit-text-box' contentEditable={true}>{contactIndeed}</div>
                <br></br><label>Twitter URL:</label> <div id="contact-twitter" className='edit-text-box' contentEditable={true}>{contactTwitter}</div>
                <br></br><label>Other Website URL:</label> <div id="contact-other-url" className='edit-text-box' contentEditable={true}>{contactOtherUrl}</div>
                <br></br>
           </div>
          <br></br>
           <div  className='save-cancel-btn-row'>
               <Button variant='outline-primary' className='row-headings' onClick={handleSave}>Save</Button>
               <Button variant='outline-primary' className='row-headings' onClick={handleCancel}>Cancel</Button>
           </div>
          
          </div>
            
     
      )}
     
      {!isEditing &&  
      <>
      <div className='pencil-btn'>
         <div  className='pencil-btn-container'>
        
             <Button onClick={()=> setIsEditing(true)} variant='outline-primary'><BsPencilFill/></Button> 
          </div>
      </div>
        
        <div className='details-container'>
          
          <Image className='wallpaper' src={image} alt="Profile" />
       
          <div className='logo-text-container'>
             <div className='media-icon-row'>
               <div className='logo-container'>
                <Image className='logo' src={logo} alt="Profile"/>
               </div>
               <div style={{paddingTop: 25}}>
                 <div className='host-name'>{companyName}</div>
                 <div className='address-text'>{companyAddress}</div>
                 <div className='members-text'>15 members</div>
                 <div className='media-icon-row'><Image src={facebookLogo} className='media-icon' />
                   <Image src={twitterLogo} className='media-icon' />
                   <Image src={linkedInLogo} className='media-icon'/>
                 </div>
               </div> 
             </div>
          
          
          <label className='user-profile-label'>User profile</label>
          <div >
            
            <div className='user-profile-box'>
              <div className='box-row'>
                <span className='row-headings'>Contact Name</span>
                <div style={{width: 470}}><Image src={profileImg} className='profile-img'/>{' '}<span id="inline-user-name-change"  contentEditable={inlineUserName} className='profile-name'>{userName}</span></div>
                <Button variant='outline-link' className='email-id-phone' onClick={()=> {handleInlineUserName(); handleInlineChanges()}} >Change</Button>
              </div>
                   <hr className='border-grey'/>
               <div className='box-row'>
                  <span className='row-headings'>Email id</span><span className='email-id-phone'>ryan.lee@firstdraft.com</span>
                  <span>phone</span><span id="inline-user-phone-change"  contentEditable={inlineUserPhone} className='email-id-phone'>{userPhone}</span>
                  <span>Social{ ' '}<BsQuestionCircle className='question-circle'/></span><Image className='social-img' src={linkedInLogo}/>
                  <Button variant='outline-link' className='email-id-phone' onClick={()=> {handleInlineUserPhone(); handleInlineChanges()}}>Change</Button>
              </div>
                   <hr className='border-grey'/>
               <div className='box-row'> 
                  <span className='row-headings'>Role as assigned</span>
                  <div style={{width: 470}}><span className='grader'>Grader</span> <span>(can grade,add notes and publish results) </span></div>
                  <Button variant='outline-link' className='email-id-phone'> Request</Button> 
              </div>
         
            </div>
         </div>

        <label className='user-profile-label'>Company Contact</label>
        <div >
          
          <div className='contact-box'>
            
            <div className='contact-row'> <span className='row-headings '>Contact</span><div><Image src={cProfileImg} className='profile-img'/><span className='contact-name'>{contactName}</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Email</span><div><span className='contact-info'>jsmith@email.com</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>LinkedIn URL</span><div><span className='contact-info'>linkedin.com/dummyprofile</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Indeed URL</span><div><span className='contact-info'>indeed.com/contestlisting</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Twitter URL</span><div><span className='contact-info'>twitter.com/dummyaccount</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>other website URL</span><div><span className='contact-info'>www.finaldraft.com</span></div></div>
            
          </div>
        </div>
       
       
        </div>
        </div>
        </>}
        </div>
    )
}
export default ProfileSummary


/*import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor'
import { AvatarEditorProps } from 'react-avatar-editor';
import wallpaper from '../../../images/wallpaper-image.png';
function ProfileSummary() {
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState(sessionStorage.getItem('image') || wallpaper); // Get image from session storage

  const handleImageChange = (e: any) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setImage(newImage);
    sessionStorage.setItem('image', newImage); // Store image in session storage
  };

  useEffect(() => {
    // Update image state when page is refreshed
    setImage(sessionStorage.getItem('image') || wallpaper);
  }, []);

  return (
    <div>
      <h1>Profile Page</h1>
      <Button onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? 'Done' : 'Edit Image'}
      </Button>
      {isEditing && (
        <div>
          <input type="file" onChange={handleImageChange} />
          <AvatarEditor
            image={image}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
        </div>
      )}
      {!isEditing && <img src={image} alt="Profile" />}
    </div>
  );
}

export default ProfileSummary;




/*import './style.scss'
import { Button, Image } from 'react-bootstrap'
import Wallpaper from '../../../images/wallpaper-image.png'
import Logo from '../../../images/account-logo.png'
import facebookLogo from '../../../images/facebookLogo.png'
import linkedInLogo from '../../../images/linkedInLogo.png'
import twitterLogo from '../../../images/twitterLogo.png'
import { useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { BsPencilFill, BsQuestionCircle } from 'react-icons/bs'
import profile from '../../../images/profile.png'
import contactProfile from '../../../images/contactProfile.png'

const ProfileSummary=() =>{

  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState(sessionStorage.getItem('image') || Wallpaper); 
  const [logo, setLogo] = useState(sessionStorage.getItem('logo') || Logo); // Get image from session storage
  const [editedImage, setEditedImage] = useState(image); // Edited image state
  const [editedLogo, setEditedLogo] = useState(logo);
  const [companyName, setCompanyName] = useState(sessionStorage.getItem('companyName') || 'Final Draft'); 
  const [companyAddress, setCompanyAddress] =  useState(sessionStorage.getItem('companyAddress') || '6600 Sunset Blvd Los Angles,CA 90028'); 
  
  const handleImageChange = (e: any) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setEditedImage(newImage); // Set edited image
  };
  const handleLogoChange = (e: any) => {
    const newLogo = URL.createObjectURL(e.target.files[0]);
    setEditedLogo(newLogo); // Set edited image
  };

  const handleSave = () => {
    const editedContent = document.getElementById("org-name")?.textContent;
    if(editedContent){
    setCompanyName(editedContent);
    sessionStorage.setItem('companyName',editedContent);}
    else{
      sessionStorage.setItem('companyName',companyName);
    
    }

  };

  const handleDone = () => {
    setIsEditing(false);
    if (editedImage || editedLogo || companyName || companyAddress) {
      setImage(editedImage); 
      sessionStorage.setItem('image', editedImage); // Store image in session storage
      setLogo(editedLogo);
      sessionStorage.setItem('logo', editedLogo);
      handleSave();
      setCompanyAddress(companyAddress);
      sessionStorage.setItem('companyAddress',companyAddress);

    }
    
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedImage(''); 
    setEditedLogo('');
    //setCompanyName(companyName);
   // setCompanyAddress(companyAddress); // Clear edited image
  };


  useEffect(() => {
    // Update image state when page is refreshed
    setImage(sessionStorage.getItem('image') || Wallpaper);
    setLogo(sessionStorage.getItem('logo') || Logo);
    setCompanyName(sessionStorage.getItem('companyName')|| companyName);
  }, []);



    return(
        <div style={{padding: 40}}>
         <h4 className='profile-summary'>Profile Summary</h4>
         
      {isEditing && (
        <div>
          <input type="file" onChange={handleImageChange} />
          <AvatarEditor
            image={editedImage? editedImage : image}
            width={970}
            height={190}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
          <input type="file" onChange={handleLogoChange} />
          <AvatarEditor
            image={editedLogo? editedLogo: logo}
            width={140}
            height={140}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
          <div><br></br>

          <label>Organization Name:</label><div id="org-name" className='host-name' contentEditable={true} >{companyName}</div>
          <br></br><label>Organization Address:</label> <div className='address-text' contentEditable={true} onBlur={(e: any) => setCompanyAddress(e.target.textContent)}>{companyAddress}</div>
          <Button variant='outline-primary' onClick={handleDone}>Done</Button>{ ' '}
          <Button variant='outline-primary' onClick={handleCancel}>Cancel</Button></div>
        </div>
      )}
     
      {!isEditing &&  
      <>
      <div className='pencil-btn'>
         <div  className='pencil-btn-container'>
        
             <Button onClick={()=> setIsEditing(true)} variant='outline-primary'><BsPencilFill/></Button> 
          </div>
      </div>
        
        <div className='details-container'>
          
          <Image className='wallpaper' src={image} alt="Profile" />
       
          <div className='logo-text-container'>
             <div className='media-icon-row'>
               <div className='logo-container'>
                <Image className='logo' src={logo} alt="Profile"/>
               </div>
               <div style={{paddingTop: 25}}>
                 <div className='host-name'>{companyName}</div>
                 <div className='address-text'>{companyAddress}</div>
                 <div className='members-text'>15 members</div>
                 <div className='media-icon-row'><Image src={facebookLogo} className='media-icon' />
                   <Image src={twitterLogo} className='media-icon' />
                   <Image src={linkedInLogo} className='media-icon'/>
                 </div>
               </div> 
             </div>
          
          
          <label className='user-profile-label'>User profile</label>
          <div className='user-profile'>
            
            
            <div className='user-profile-box'>
              <div className='box-row'>
                <span className='row-headings'>Contact Name</span>
                <div style={{width: 500}}><Image src={profile} className='profile-img'/>{' '}<span className='profile-name'>{' '}Ryan Lee</span></div>
                <span className='email-id-phone'>Change</span>
              </div>
                   <hr className='border-grey'/>
               <div className='box-row'>
                  <span className='row-headings'>Email id</span><span className='email-id-phone'>ryan.lee@firstdraft.com</span>
                  <span>phone</span><span className='email-id-phone'>1234567890</span>
                  <span>Social{ ' '}<BsQuestionCircle className='question-circle'/></span><Image className='social-img' src={linkedInLogo}/>
                  <span className='email-id-phone'>Change</span>
              </div>
                   <hr className='border-grey'/>
               <div className='box-row'> 
                  <span className='row-headings'>Role as assigned</span>
                  <div style={{width: 500}}><span className='grader'>Grader</span> <span>(can grade,add notes and publish results) </span></div>
                  <span className='email-id-phone'>Request</span>
              </div>
         
            </div>
         </div>

        <label className='user-profile-label'>Company Contact</label>
        <div className='user-profile'>
          
          <div className='contact-box'>
            
            <div className='contact-row'> <span className='row-headings '>Contact</span><div><Image src={contactProfile} className='profile-img'/><span className='contact-name'>John Smith</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Email</span><div><span className='contact-info'>jsmith@email.com</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>LinkedIn URL</span><div><span className='contact-info'>linkedin.com/dummyprofile</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Indeed URL</span><div><span className='contact-info'>indeed.com/contestlisting</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>Twitter URL</span><div><span className='contact-info'>twitter.com/dummyaccount</span></div></div>
            <hr className='border-grey'/>
            <div className='contact-row'> <span className='row-headings '>other website URL</span><div><span className='contact-info'>www.finaldraft.com</span></div></div>
            
          </div>
        </div>
       
       
        </div>
        </div>
        </>}
        </div>
    )
}
export default ProfileSummary*/


/*import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor'
import { AvatarEditorProps } from 'react-avatar-editor';
import wallpaper from '../../../images/wallpaper-image.png';
function ProfileSummary() {
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState(sessionStorage.getItem('image') || wallpaper); // Get image from session storage

  const handleImageChange = (e: any) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setImage(newImage);
    sessionStorage.setItem('image', newImage); // Store image in session storage
  };

  useEffect(() => {
    // Update image state when page is refreshed
    setImage(sessionStorage.getItem('image') || wallpaper);
  }, []);

  return (
    <div>
      <h1>Profile Page</h1>
      <Button onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? 'Done' : 'Edit Image'}
      </Button>
      {isEditing && (
        <div>
          <input type="file" onChange={handleImageChange} />
          <AvatarEditor
            image={image}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
        </div>
      )}
      {!isEditing && <img src={image} alt="Profile" />}
    </div>
  );
}

export default ProfileSummary;*/


