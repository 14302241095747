import { createSlice ,PayloadAction} from '@reduxjs/toolkit';
import { fetchContesApplication, fetchContestList } from '../../views/pages/contest-list/contest-list.service';
import { IContestInfo, IContestStories } from '../../views/pages/contest-list/contest.model';

export interface IContestListState  extends IContestInfo {}

interface IContestState {
    contests: IContestListState[],
    contest: IContestListState | null,
    totalContests: number
    applications: IContestStories | null
}
  
  const defaultState: IContestState = { 
    contests: [],
    totalContests: 0,
    contest: null ,
    applications: null
  };
  
  export const ContestReducer = createSlice({
    name: 'ContestReducer',
    initialState: defaultState,
    reducers: {
      clearApplications: (state: IContestState) => {
        state.applications = null
    }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchContestList.fulfilled, (state: IContestState, action:PayloadAction<any>) => {
        state.contests = action.payload.rows || []
        state.totalContests = action.payload.count || 0
      })
      .addCase(fetchContesApplication.fulfilled, (state: IContestState, action:PayloadAction<any>) => {
        state.applications = action.payload.data || []
      })
    },
  });
  
  export const {clearApplications} = ContestReducer.actions
  export default ContestReducer.reducer;