import "./style.scss";
import React, { useState, useEffect, useRef, RefObject } from "react";
import { Button, Image } from "react-bootstrap";
import Select, {
  ActionMeta,
  MultiValue,
  Options,
  SingleValue,
} from "react-select";
import { BsPlus } from "react-icons/bs";
import applePay from "../../../images/applePay.png";
import payPal from "../../../images/payPalLogo.png";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Header } from "./header";
import { AppDispatch, RootState, store } from "../../../redux-store";
import { fetchContestData } from "./create-contest-services";
import { useDispatch, useSelector } from "react-redux";
import { fetchGenresList } from "./genre-services";
import {
  IGenresState,
  ILanguagesState,
} from "../../../redux-store/user-preferences/user-preferences.redux";
import { fetchLanguagesList } from "./language-services";
import { useNavigate } from "react-router-dom";
import { StepperFooterButtons } from "../../../shared/components/stepper-footer-buttons";
import { Step } from "../../../shared/models/steppers.model";
import { StepperBreadCrumb } from "../../../shared/components/stepper-breadcrumbs";

import { DEFAULT_COUNTRY_SELECTED } from "../../../constants/constants";
import { DropdownOption } from "../../../models/common.model";
import { ContestTermsDisplayEnum } from "../contest-list/contest.model";
import { ContestInput } from "../../../models/contest.model";
import { showErrorToast, showSuccessToast } from "../../../services/toast.service";
import { fileUpload } from "../../../services/file-uplaoder.service";
import { AxiosResponse } from "axios";
import { HttpResponseType } from "../../../models/https.model";

interface DeadlineType {
  deadline: string;
  entryFees: string;
}

interface ContestBasicType {
  contestTitle: string;
  contestDescription: string;
  contestGenre: MultiValue<DropdownOption<number>>;
  contestLanguage: MultiValue<DropdownOption<number>>;
  contestCountry: SingleValue<DropdownOption<number>>;
}

interface ContestPrizeAndRuleType {
  firstPrize: string;
  secondPrize: string;
  thirdPrize: string;
  entryRules: string;
  rulesEligibility: string;
  addRequirements: string;
}

interface ContestTimelineType {
  startDate: string;
  endDate: string;
  hasFee: boolean;
  deadline: DeadlineType[];
}

interface ContestContactAndLegalType {
  name: string;
  designation: string;
  phoneNumber: string;
  countryName: string;
  countryCode: string;
  email: string;
  linkedInProfile: string;
  termsAndConditions: string;
  privacyPolicy: string;
  termsOptionsDisplayType: ContestTermsDisplayEnum;
  privacyPolicyDisplayType: ContestTermsDisplayEnum;
}

const ContestForm = () => {
  const bannerRef = React.createRef<any>();

  const steps: Step[] = [
    {
      step: 1,
      title: "Contest Basics",
      info: "Provide a catchy contest name, description, and specify the contest category.",
    },
    {
      step: 2,
      title: "Prizes & Rules",
      info: "Set contest rewards and guidelines.",
    },
    {
      step: 3,
      title: "Contest details",
      info: "Crucial information about the contest, including its timeline, entry details, submission platform, and any special requirements.",
    },

    {
      step: 4,
      title: "Contact & Legal",
      info: "Contact information for contest-related communication. Access to legal information, including terms and conditions and the privacy policy.",
    },

    {
      step: 5,
      title: "Review And Submit",
      info: "Summary",
    },
  ];

  const countries: DropdownOption<number>[] = [DEFAULT_COUNTRY_SELECTED];

  const dispatch: AppDispatch = useDispatch();
  const state: RootState = store.getState();

  const _genres = (state: RootState) => state.UserPreferencesReducer.genres;
  const genres: IGenresState[] = useSelector(_genres);

  const _languages = (state: RootState) =>
    state.UserPreferencesReducer.languages;
  const languages: ILanguagesState[] = useSelector(_languages);

  const getGenres = async () => {
    dispatch(fetchGenresList());
  };

  const getLanguages = async () => {
    dispatch(fetchLanguagesList());
  };

  useEffect(() => {
    getGenres();
    getLanguages();
  }, []);

  useEffect(() => {
    const genreList = genres.map((item, index: number) => {
      return {
        value: item.id,
        label: item.name || "",
      } as DropdownOption<number>;
    });
    setGenresList(genreList);
  }, [genres]);

  useEffect(() => {
    const languageList = languages.map((item, index: number) => {
      return { value: item.id, label: item.name || "" };
    });
    setLanguagesList(languageList);
  }, [languages]);

  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const [showReview, setShowReview] = useState<boolean>(false);

  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const nextStep = () => {
    if (steps.length !== currentStep.step) {
      setCurrentStep(steps[currentStep.step]);
    }
  };

  const prevStep = () => {
    if (currentStep.step > 1) {
      setCurrentStep(steps[currentStep.step - 2]);
    }
  };

  const [genresList, setGenresList] = useState<DropdownOption<number>[]>();
  const [languagesList, setLanguagesList] =
    useState<DropdownOption<number>[]>();

  //step1
  const [showContestBasics, setShowContestBasics] = useState(true);
  const [showPrizeAndRules, setShowPrizeAndRules] = useState(true);
  const [showContestDetails, setShowContestDetails] = useState(true);
  const [showContactAndLegal, setShowContactAndLegal] = useState(true);

  const [banner, setBanner] = useState<File>();
  const [bannerFile, setBannerFile] = useState<any>();

  const [contestBasicValues, setContestBacisValues] =
    useState<ContestBasicType>({
      contestDescription: "",
      contestTitle: "",
      contestCountry: DEFAULT_COUNTRY_SELECTED,
      contestGenre: [],
      contestLanguage: [],
    });

  const [contestPrizeAndRules, setContestPrizeAndRules] =
    useState<ContestPrizeAndRuleType>({
      addRequirements: "",
      entryRules: "",
      firstPrize: "",
      rulesEligibility: "",
      secondPrize: "",
      thirdPrize: "",
    });

  const [contestTimeline, setContestTimeline] = useState<ContestTimelineType>({
    deadline: [
      {
        deadline: new Date().toString(),
        entryFees: "",
      },
    ],
    endDate: "",
    hasFee: false,
    startDate: "",
  });

  const [contestContactAndLegal, setContestContactAndLegal] =
    useState<ContestContactAndLegalType>({
      countryCode: "",
      countryName: "",
      designation: "",
      email: "",
      linkedInProfile: "",
      name: "",
      phoneNumber: "",
      privacyPolicy: "",
      termsAndConditions: "",
      privacyPolicyDisplayType: "CHECKBOX",
      termsOptionsDisplayType: "CHECKBOX",
    });

  const handleAdd = () => {
    const deadlines = contestTimeline.deadline;
    deadlines[deadlines.length] = {
      deadline: new Date().toDateString(),
      entryFees: "",
    };

    setContestTimeline({
      ...contestTimeline,
      deadline: deadlines,
    });
  };

  const handleDeadlineFeeChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const currentDeadline = contestTimeline.deadline;
    if (currentDeadline[index]) {
      currentDeadline[index][event.target.name as keyof DeadlineType] =
        event.target.value;
    }
    setContestTimeline({
      ...contestTimeline,
      deadline: currentDeadline,
    });
  };

  const handleTermsType = (event: any) => {
    setContestContactAndLegal({
      ...contestContactAndLegal,
      termsOptionsDisplayType: event.target.value,
    });
  };

  const handlePrivacyPolicyType = (event: any) => {
    setContestContactAndLegal({
      ...contestContactAndLegal,
      privacyPolicyDisplayType: event.target.value,
    });
  };

  const handleSubmit = async () => {
    if (banner) {
      fileUpload(banner, "CONTEST_BANNER").then(
        async (response: AxiosResponse<HttpResponseType<string>>) => {
          const contestData: ContestInput = {
            title: contestBasicValues.contestTitle,
            subject: contestBasicValues.contestDescription,
            //banner: banner,
            startsOn: contestTimeline.startDate,
            expiresOn: contestTimeline.endDate,
            loglineLimit: 500,
            synopseLimit: 500,
            firstPrize: contestPrizeAndRules.firstPrize,
            sencondPrize: contestPrizeAndRules.secondPrize,
            thirdPrize: contestPrizeAndRules.thirdPrize,
            entryRules: contestPrizeAndRules.entryRules,
            rulesAndEligibility: contestPrizeAndRules.rulesEligibility,
            additionalRequirements: contestPrizeAndRules.addRequirements,
            hasEntryFeeBeforeDeadline: contestTimeline.hasFee,
            contactName: contestContactAndLegal.name,
            contactDesignation: contestContactAndLegal.designation,
            contactPhoneNumber:
              "+" +
              contestContactAndLegal.countryCode +
              " " +
              contestContactAndLegal.phoneNumber,
            contactEmail: contestContactAndLegal.email,
            contactLinkedInProfile: contestContactAndLegal.linkedInProfile,
            temrsAndConditions: contestContactAndLegal.termsAndConditions,
            privacyPolicy: contestContactAndLegal.privacyPolicy,
            privacyPolicyDisplayType:
              contestContactAndLegal.privacyPolicyDisplayType,
            termsOptionsDisplayType:
              contestContactAndLegal.termsOptionsDisplayType,
            genres: contestBasicValues.contestGenre.map((genre) => genre.value),
            languages: contestBasicValues.contestLanguage.map(
              (genre) => genre.value
            ),
            allowedContent: "TEXT",
            countries: contestBasicValues.contestCountry
              ? [contestBasicValues.contestCountry?.value]
              : [231],
            banner: response.data.data,
            deadlines: contestTimeline.deadline.map((deadline) => {
              return {
                deadline: deadline.deadline,
                fees: deadline.entryFees,
              };
            }),
            status: "CREATED",
          };
          try {
            await dispatch(fetchContestData(contestData)).unwrap().then(response => {
              if(response){
                navigate("/contest-list",{state: {formSubmitted: true}});
                showSuccessToast('Contest Created Successfully');
              }
              else{
                showErrorToast("Error while creating contest");
              }
            });
          } catch (err) {
            showErrorToast("Error while creating contest");
          }
        }
      );
    }
  };

  const handleStep1Submit = () => {
  
      if(contestBasicValues.contestTitle === '')
       alert("Contest Title Required");
      else if(contestBasicValues.contestDescription === '')
       alert("Contest Description Required");
      else if(contestBasicValues.contestGenre.length < 2)
       alert("Please choose atleast 2 geners");
      else if(contestBasicValues.contestLanguage.length < 1)
      alert("Please choose language");
      else if(!banner){
        alert('Banner is required...');
      }
      else
      {
        nextStep();
        return;
      }
  };

  const handleStep2Submit = () => {
  
      if(contestPrizeAndRules.entryRules === '')
      {
        alert('Please fill entry rules')
      }
      else
      {
        nextStep();
        return;
      }
  };

  const handleStep3Submit = () => {
    
      if(contestTimeline.startDate === ''){
        alert('please fill start date');
      }
      else if(contestTimeline.endDate === ''){
        alert('please fill end date');
      }
      else 
      {
        nextStep();
        return;  
      }
    
  };

  const handleStep4Submit = () => {
    
     if(contestContactAndLegal.name === ''){
      alert('please fill name');
     }
     else if(contestContactAndLegal.designation === ''){
      alert('please fill designation');
     }
     else if(contestContactAndLegal.email === ''){
      alert('please fill email id');
     }
     else if(contestContactAndLegal.termsAndConditions === ''){
      alert('please fill terms and conditions');
     }
     else if(contestContactAndLegal.privacyPolicy === ''){
      alert('please fill privacy policy');
     }
     else
     {
      nextStep();
      return;
     }
    
  };

  const stepContinue = (step: Step) => {
    switch (step.step) {
      case 1:
        handleStep1Submit();
        break;
      case 2:
        handleStep2Submit();
        break;
      case 3:
        handleStep3Submit();
        break;
      case 4:
        handleStep4Submit();
        break;
      case 5:
        handleSubmit();
        break;
      default:
        break;
    }
  };

  


  const cancelStep = (step: Step) => {

     switch (step.step) {
       case steps[0].step:
        navigate("/contest-list");
    //     setContestTitle('');
    //     setContestDescription('');
    //     setSelectedGenre([]);
    //     setSelectedCountry({ value: 'Select...', label: 'Select...' });
    //     setSelectedLanguage([]);
         break;
       case steps[1].step:
            prevStep();
    //     setFirstPrize('');
    //     setSecondPrize('');
    //     setThirdPrize('');
    //     setEntryRules('');
    //     setAddRequirements('');
    //     setRulesEligibility('');
           break;
       case steps[2].step:
        prevStep();
    //     setStartDate('');
    //     setEndDate('');
    //     setDeadline(['']);
    //     setEntryFees(['']);
        break;
       case steps[3].step:
        prevStep();
    //     setName('');
    //     setDesignation('');
    //     setPhoneNumber('');
    //     setCountryName('');
    //     setLinkedInProfile('');
    //     setTermsAndConditions('');
    //     setPrivacyPolicy('');
         break;
       case steps[4].step:
         prevStep();
         break;
       default:
         break;
     }
  };

  const onClickStep = (step: Step) => {
    setCurrentStep(step);
  };

  const onContestBasicFormValueChange = (ev: React.ChangeEvent<any>) => {
    const values = contestBasicValues;
    if (values) {
        const key = ev.target.name as keyof ContestBasicType;
        let value = ev.target.value;
        if (key === 'contestTitle' && value.length > 90) {
            value = value.substring(0, 90);
        } else if (key === 'contestDescription' && value.length > 500) {
            value = value.substring(0, 500);
        }
        values[key] = value;
    }
    setContestBacisValues({ ...values });
};

const onContestBasicSelectValueChange = (
  newValue:
    | MultiValue<DropdownOption<number>>
    | SingleValue<DropdownOption<number>>,
  actionMeta: ActionMeta<any>,
  name: keyof ContestBasicType
) => {

  if (name === "contestGenre") {
    if (Array.isArray(newValue) && newValue.length > 5) {
      alert('You can select up to 5 genres only.');
      return;  // Do not update the state if more than 5 genres are selected.
    }
    contestBasicValues[name] = newValue as MultiValue<DropdownOption<number>>;
  } else if (name === "contestLanguage") {
    contestBasicValues[name] = newValue as MultiValue<DropdownOption<number>>;
  } else if (name === "contestCountry") {
    contestBasicValues[name] = newValue as SingleValue<DropdownOption<number>>;
  }
  setContestBacisValues({ ...contestBasicValues });
};


  const onContestPrizeAndRulesChange = (ev: React.ChangeEvent<any>) => {
    const values = { ...contestPrizeAndRules };
    const key = ev.target.name as keyof ContestPrizeAndRuleType;
    let value = ev.target.value;

    if (key === 'firstPrize' || key === 'secondPrize' || key === 'thirdPrize') {
        if (value.length > 90) {
            value = value.substring(0, 90);
        }
    } else if (key === 'addRequirements' || key === 'entryRules') {
        if (value.length > 250) {
            value = value.substring(0, 250);
        }
    } else if (key === 'rulesEligibility') {
        if (value.length > 500) {
            value = value.substring(0, 500);
        }
    }

    values[key] = value;
    setContestPrizeAndRules(values);
};

  const onContestTimelineChange = (ev: React.ChangeEvent<any>) => {
    const key: keyof ContestTimelineType = ev.target.name;
    const values = contestTimeline;
    if (key === "endDate" || key === "startDate") {
      values[key] = ev.target.value;
    } else if (key === "hasFee") {
      values[key] = ev.target.checked;
    }
    setContestTimeline({ ...values });
  };

  const onContestContactAndLegaChange = (ev: React.ChangeEvent<any>) => {

    const values = { ...contestContactAndLegal };
    const key = ev.target.name as keyof ContestContactAndLegalType;
    let value = ev.target.value;

    if (key === 'termsAndConditions' || key === 'privacyPolicy') {
        if (value.length > 250) {
            value = value.substring(0, 250);
        }
    } 
    values[key] = value;
    setContestContactAndLegal(values);
  };

  const handlePhoneAndCountryCodeChange = (
    phone: string,
    data: CountryData
  ) => {
    setContestContactAndLegal({
      ...contestContactAndLegal,
      phoneNumber: phone,
      countryName: data.name,
      countryCode: data.dialCode,
    });
  };

  const onBannerSelect = () => {
    if (bannerRef.current) bannerRef.current.click();
  };

  const addPreview = (file: File, callback: any) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", function (e) {
      if (this.result) {
        callback(this.result);
      }
    });
  };

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target?.files.length > 0) {
      setBanner(event.target.files[0]);
      addPreview(event.target.files[0], setBannerFile);
    }
  };

  return (
    <div>
      {/* <TabBar currentStep={step} onClickContestBasics={() => setStep(1)} onClickPrizes={step >= 2 ? (() => setStep(2)) : (() => setStep(step))} onClickContestDetails={step >= 3 ? (() => setStep(3)) : (() => setStep(step))} onClickContactDetails={step >= 4 ? (() => setStep(4)) : (() => setStep(step))} /> */}
      <StepperBreadCrumb
        steps={steps}
        currentStep={currentStep}
        onClickStep={onClickStep}
      />

      {(currentStep.title === steps[0].title || currentStep.step === steps[4].step  ) && (
        <div>
          <div className="contest-basics-and-queries-container">
            <Header
              onEditClick={()=> setCurrentStep(steps[0])}
              currentStep={currentStep.step}
              queryNumber={1}
              queryHeading="Contest Basics"
              querySubHeading="Provide a catchy contest name, description, and specify the contest category."
              showContent={showContestBasics}
              onClick={() => setShowContestBasics(!showContestBasics)}
            />
           {showContestBasics && 
            <div className="queries-container">
              <div
                className="contest-queries-box-row"
                style={{ paddingTop: 30 }}
              >
                <div className="contest-queries">
                  <span className="contest-basics">Contest Title</span>
                  <span className="contest-sub-text">
                    {" "}
                    Provide a catchy contest name and limit your words upto 90
                    characters.
                  </span>
                </div>
                <div style={{ display: "inline-block", position: "relative" }}>
                  <input
                    disabled={currentStep.step === steps[4].step ? true : false}
                    placeholder="Contest Name"
                    type="text"
                    value={contestBasicValues?.contestTitle}
                    onChange={onContestBasicFormValueChange}
                    name="contestTitle"
                    className="text-input"
                    required
                  />
                  <div>
                    <span className="input-text-length">
                      {contestBasicValues?.contestTitle?.length || 0}/90
                    </span>
                  </div>
                  <div>
                    <span className="contest-box-sub-text">
                      You can’t change the name of the contest.
                    </span>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Contest Descirption</span>
                  <span className="contest-sub-text">
                    {" "}
                    Up to 500 characters maximum.
                  </span>
                </div>
                <div style={{ display: "inline-block", position: "relative" }}>
                  <textarea
                    disabled={currentStep.step === steps[4].step ? true : false}
                    placeholder="write your description here..."
                    style={{
                      resize: "none",
                      overflow: "scroll",
                      paddingRight: 60,
                    }}
                    value={contestBasicValues?.contestDescription}
                    onChange={onContestBasicFormValueChange}
                    name="contestDescription"
                    className="text-multi-input"
                    required
                  />
                  <div>
                    <span className="input-multi-text-length">
                      {contestBasicValues?.contestDescription.length}/500
                    </span>
                  </div>
                  <div>
                    <span className="contest-box-sub-text">
                      You can edit this for any changes, will be posted as an
                      update on the same thread.
                    </span>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Contest Category</span>
                  <span className="contest-sub-text">
                    {" "}
                    You can select a maximum of 5 genres and a minimum of 2
                    genres from the list.
                  </span>
                </div>
                <div>
                  <label className="label-txt" style={{ paddingLeft: 6 }}>
                    Genre
                  </label>
                  <Select
                    isDisabled={currentStep.step === steps[4].step ? true : false}
                    options={genresList}
                    defaultValue={contestBasicValues.contestGenre}
                    value={contestBasicValues.contestGenre}
                    closeMenuOnSelect={false}
                    onChange={(newValue, action) =>
                      onContestBasicSelectValueChange(
                        newValue,
                        action,
                        "contestGenre"
                      )
                    }
                    isMulti={true}
                    styles={{
                      control: (provided: any, state: any) => ({
                        ...provided,
                        borderColor: "grey",
                      }),
                    }}
                    name="contestGenre"
                    className="text-input"
                    required
                  />
                  {currentStep.step === steps[4].step && (
                    <div className="selected-genre-language-country-container">
                      {
                        // contestGenre.map((genre: { value: number, label: string }, index: number) =>
                        //   <div>
                        //     <p className='selected-genre-language-country'>{genre.label}</p>
                        //   </div>
                        // )
                      }
                    </div>
                  )}
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Country & Language</span>
                  <span className="contest-sub-text">
                    {" "}
                    This will be your primary market. You can target only one
                    country at once.You can add at least 15{" "}
                  </span>
                  <div style={{ paddingTop: 10 }}>
                    <Button className="add-another-market">
                      <BsPlus /> Add another market
                    </Button>
                  </div>
                </div>

                <div>
                  <div className="country-language-row">
                    <div>
                      <label className="label-txt" style={{ paddingLeft: 2 }}>
                        Country
                      </label>
                      <br></br>
                      <Select
                        isDisabled={true}
                        options={countries}
                        defaultValue={contestBasicValues.contestCountry}
                        onChange={(newValue, action) =>
                          onContestBasicSelectValueChange(
                            newValue,
                            action,
                            "contestCountry"
                          )
                        }
                        value={contestBasicValues.contestCountry}
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            borderColor: "grey",
                          }),
                        }}
                        name="contestCountry"
                        className="country-language-input"
                      />
                    </div>

                    <div>
                      <label className="label-txt" style={{ paddingLeft: 2 }}>
                        Language
                      </label>
                      <Select
                        isDisabled={currentStep.step === steps[4].step ? true : false}
                        options={languagesList || []}
                        defaultValue={contestBasicValues.contestLanguage}
                        value={contestBasicValues.contestLanguage}
                        closeMenuOnSelect={false}
                        onChange={(newValue, action) =>
                          onContestBasicSelectValueChange(
                            newValue,
                            action,
                            "contestLanguage"
                          )
                        }
                        isMulti={true}
                        name="contestLanguage"
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            borderColor: "grey",
                            overflowY: "auto",
                            height: 40,
                          }),
                        }}
                        className="country-language-input"
                        required
                      />
                    </div>
                  </div>
                  {currentStep.step === steps[4].step && (
                    <div className="selected-genre-language-country-container">
                      {/* <p className='selected-genre-language-country'>{contestCountry?.label}</p> */}
                      {
                        // contestLanguage?.map((language: { value: number, label: string }, index: number) =>
                        //   <div>
                        //     <p className='selected-genre-language-country'>{language.label}</p>
                        //   </div>
                        // )
                      }
                    </div>
                  )}
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Banner</span>
                  <span className="contest-sub-text">
                    Select Contest Banner
                  </span>
                </div>

                <div>
                  <div className="country-language-row row">
                      <div className="col-6">
                        <input
                          type="file"
                          ref={bannerRef}
                          className="d-none"
                          id="banner"
                          disabled={currentStep.step === steps[4].step ? true : false}
                          onChange={(evnt) => onFileInputChange(evnt)}
                        />
                        <Button
                          disabled={currentStep.step === steps[4].step ? true : false}
                          className="btn"
                          type="button"
                          onClick={onBannerSelect}
                        >
                          Select Banner
                        </Button>
                      </div>
                      <div className="col-6">
                        {banner && (
                          <img
                            src={bannerFile}
                            style={{ height: 60, width: 100 }}
                          />
                        )}
                      </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      )}

      {(currentStep.title === steps[1].title || currentStep.step === steps[4].step) && (
        <div>
          <div className="contest-basics-and-queries-container">
            <Header
              queryNumber={2}
              queryHeading="Prizes & Rules"
              querySubHeading="Set contest rewards and guidelines."
              showContent={showPrizeAndRules}
              onClick={() => setShowPrizeAndRules(!showPrizeAndRules)}
            />
            {showPrizeAndRules && 
            <div className="queries-container"> 
              <div className="contest-queries-box-row" style={{ paddingTop: 30 }}>
              
                <div className="contest-queries">
                  <span className="contest-basics">Prizes for Winners </span>
                  <span className="contest-sub-text">
                    {" "}
                    Provide prize specifics, including type, value, and terms.
                    Include 1 winner minimum and Up to 90 characters.
                  </span>
                </div>

                <div style={{ display: "inline-block", position: "relative" }}>
                  <div className="prize-number-flex">
                    <p style={{ width: 12 }}>1.</p>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="Description goes here..."
                      type="text"
                      value={contestPrizeAndRules.firstPrize}
                      onChange={onContestPrizeAndRulesChange}
                      name="firstPrize"
                      className="prize-text-box"
                      required
                    />
                    <div className="prize1-length-container">
                      <span className="prize1-length">
                        {contestPrizeAndRules.firstPrize.length}/90
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: 15 }} className="prize-number-flex">
                    <p style={{ width: 12 }}>2.</p>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="Description goes here... "
                      type="text"
                      value={contestPrizeAndRules.secondPrize}
                      onChange={onContestPrizeAndRulesChange}
                      name="secondPrize"
                      className="prize-text-box"
                      required
                    />
                    <div className="prize2-length-container">
                      <span className="prize2-length">
                        {contestPrizeAndRules.secondPrize.length}/90
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: 15 }} className="prize-number-flex">
                    <p style={{ width: 12 }}>3.</p>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="Description goes here..."
                      type="text"
                      value={contestPrizeAndRules.thirdPrize}
                      onChange={onContestPrizeAndRulesChange}
                      name="thirdPrize"
                      className="prize-text-box"
                      required
                    />
                    <div className="prize3-length-container">
                      <span className="prize3-length">
                        {contestPrizeAndRules.thirdPrize.length}/90
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Entry Rules</span>
                  <span className="contest-sub-text">
                    Outline word limits, divatting, eligibility, and
                    instructions for entries. Up to 250 characters.
                  </span>
                </div>
                <div style={{ display: "inline-block", position: "relative" }}>
                  <textarea
                    disabled={currentStep.step === steps[4].step ? true : false}
                    placeholder="write your description here..."
                    value={contestPrizeAndRules.entryRules}
                    style={{
                      resize: "none",
                      overflow: "scroll",
                      paddingRight: 60,
                    }}
                    onChange={onContestPrizeAndRulesChange}
                    name="entryRules"
                    className="entry-rules-box"
                    required
                  />
                  <div>
                    <span className="entry-rules-input-count-length">
                      {contestPrizeAndRules.entryRules.length}/250
                    </span>
                  </div>
                  <div>
                    <span className="contest-box-sub-text">
                      You cannot edit this section after posting.
                    </span>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Rules & Eligibility</span>
                  <span className="contest-sub-text">
                    {" "}
                    Define key factors like creativity, originality, rule
                    adherence, and theme alignment for entry evaluation. Clear
                    criteria ensure fairness, consistency, and contest success.
                    Up to 500 characters.
                  </span>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  <textarea
                    disabled={currentStep.step === steps[4].step ? true : false}
                    placeholder="write your description here..."
                    value={contestPrizeAndRules.rulesEligibility}
                    style={{
                      resize: "none",
                      overflow: "scroll",
                      paddingRight: 60,
                    }}
                    onChange={onContestPrizeAndRulesChange}
                    className="text-multi-input"
                    name="rulesEligibility"
                    required
                  />
                  <div >
                    <span className="input-multi-text-length">
                      {contestPrizeAndRules.rulesEligibility.length}/500
                    </span>
                  </div>
                  <div>
                    <span className="contest-box-sub-text">
                      You can edit this for any changes, will be posted as an
                      update on the same thread.
                    </span>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">
                    Additional requirements
                  </span>
                  <span className="contest-sub-text">
                    {" "}
                    Include any extra conditions or specific expectations beyond
                    the entry rules for participants to consider when submitting
                    entries. Up to 250 characters.{" "}
                  </span>
                </div>

                <div>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <textarea
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="write your description here..."
                      value={contestPrizeAndRules.addRequirements}
                      style={{
                        resize: "none",
                        overflow: "scroll",
                        paddingRight: 60,
                      }}
                      onChange={onContestPrizeAndRulesChange}
                      className="entry-rules-box"
                      name="addRequirements"
                      required
                    />
                    <div>
                      <span className="entry-rules-input-count-length">
                        {contestPrizeAndRules.addRequirements.length}/250
                      </span>
                    </div>
                    <div>
                      <span className="contest-box-sub-text">
                        You can edit this for any changes, will be posted as an
                        update on the same thread.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      )}

      {(currentStep.title === steps[2].title || currentStep.step === steps[4].step) && (
        <div>
          <div className="contest-basics-and-queries-container">
            <Header
              queryNumber={3}
              queryHeading="Contest details"
              querySubHeading="Crucial information about the contest, including its timeline, entry details, submission platform, and any special requirements."
              showContent={showContestDetails}
              onClick={() => setShowContestDetails(!showContestDetails)}
            />
            {showContestDetails && 
            <div className="queries-container">
              <div
                className="contest-queries-box-row"
                style={{ paddingTop: 30 }}
              >
                <div className="contest-queries">
                  <span className="contest-basics">Contest Duration</span>
                  <span className="contest-sub-text">
                    Specify the start and end dates for your contest.
                    Participants need to know the timeframe within which they
                    can submit their entries and when winners will be announced.
                  </span>
                </div>
                <div>
                  <div>
                    <label className="start-end-date-text">Start Date</label>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      type="Date"
                      value={contestTimeline.startDate}
                      name="startDate"
                      onChange={onContestTimelineChange}
                      className="start-end-date"
                      required
                    />
                  </div>
                  <br></br>
                  <div>
                    <label className="start-end-date-text">End Date</label>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      type="Date"
                      value={contestTimeline.endDate}
                      name="endDate"
                      onChange={onContestTimelineChange}
                      className="start-end-date"
                      required
                    />
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Entry Rules</span>
                  <span className="contest-sub-text">
                    Outline word limits, divatting, eligibility, and
                    instructions for entries. Up to 250 characters.
                  </span>
                  <br></br>
                  <span>
                    <input
                      disabled={currentStep.step === steps[4].step ? true : false}
                      type="checkbox"
                      name="hasFee"
                      checked={contestTimeline.hasFee}
                      onChange={onContestTimelineChange}
                    />
                    <label className="checkbox-fee-text">
                      No Fees at the contest end date
                    </label>{" "}
                  </span>
                </div>

                <div>
                  {contestTimeline.deadline.map((dline, index) => (
                    <div key={index}>
                      <div className="deadline-fees-row">
                        <div>
                          <label className="label-txt">
                            Deadline {index + 1}
                          </label>
                          <input
                            disabled={currentStep.step === steps[4].step ? true : false}
                            type="date"
                            name="deadline"
                            value={dline.deadline}
                            onChange={(event) =>
                              handleDeadlineFeeChange(index, event)
                            }
                            className="deadline-fees-input"
                          />
                        </div>
                        <div>
                          <label className="label-txt">
                            Entry Fees {index + 1}
                          </label>
                          <input
                            disabled={currentStep.step === steps[4].step ? true : false}
                            type="number"
                            name="entryFees"
                            value={dline.entryFees}
                            onChange={(event) =>
                              handleDeadlineFeeChange(index, event)
                            }
                            className="deadline-fees-input"
                          />
                        </div>
                      </div>
                      <br></br>
                    </div>
                  ))}
                  <button
                    disabled={currentStep.step === steps[4].step ? true : false}
                    onClick={handleAdd}
                    className="add-another-market"
                  >
                    <BsPlus /> Add another deadline
                  </button>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Payment Account Set up</span>
                  <span className="contest-sub-text">
                    {" "}
                    Select the preferred method or platform for collecting
                    payment for entry.
                  </span>
                </div>
                <div>
                  <div className="link-account">
                    <label className="label-txt">Link Account</label>

                    <div>
                      <Image style={{ width: 50 }} src={applePay} />{" "}
                      <Image style={{ width: 50 }} src={payPal} />
                    </div>
                    <div>
                      <button className="add-another-market">
                        <BsPlus /> Create QR Code
                      </button>
                    </div>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      )}

      {(currentStep.title === steps[3].title || currentStep.step === steps[4].step) && (
        <div>
          <div className="contest-basics-and-queries-container">
            <Header
              queryNumber={4}
              queryHeading="Contact & Legal"
              querySubHeading="Contact information for contest-related communication. Access to legal information, including terms and conditions and the privacy policy."
              showContent={showContactAndLegal}
              onClick={() => setShowContactAndLegal(!showContactAndLegal)}
            />
            {showContactAndLegal && 
            <div className="queries-container">
              <div
                className="contest-queries-box-row"
                style={{ paddingTop: 30 }}
              >
                <div className="contest-queries">
                  <span className="contest-basics">Contact Information</span>
                  <span className="contest-sub-text">
                    This will ensure that we can reach out to you for any
                    contest-related communications or inquiries. Your contact
                    details will be kept confidential and used exclusively for
                    contest purposes.
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      columnGap: 20,
                      rowGap: 20,
                      width: 400,
                    }}
                  >
                    <div>
                      <label className="label-txt">Name</label>
                      <br></br>
                      <input
                        disabled={currentStep.step === steps[4].step ? true : false}
                        type="text"
                        placeholder="Name"
                        value={contestContactAndLegal.name}
                        name="name"
                        onChange={onContestContactAndLegaChange}
                        className="contact-details-text"
                      />
                    </div>

                    <div>
                      <label className="label-txt">Designation</label>
                      <br></br>
                      <input
                        disabled={currentStep.step === steps[4].step ? true : false}
                        type="text"
                        placeholder="Designation"
                        value={contestContactAndLegal.designation}
                        name="designation"
                        onChange={onContestContactAndLegaChange}
                        className="contact-details-text"
                      />
                    </div>

                    <div>
                      <label className="label-txt">Phone number</label>
                      <br></br>
                      <PhoneInput
                        disabled={currentStep.step === steps[4].step ? true : false}
                        country={contestContactAndLegal.countryName}
                        inputStyle={{ width: 185 }}
                        value={contestContactAndLegal.phoneNumber}
                        onChange={handlePhoneAndCountryCodeChange}
                      />
                    </div>

                    <div>
                      <label className="label-txt">Email</label>
                      <br></br>
                      <input
                        disabled={currentStep.step === steps[4].step ? true : false}
                        type="text"
                        placeholder="xyz@gmaill.com"
                        value={contestContactAndLegal.email}
                        name="email"
                        onChange={onContestContactAndLegaChange}
                        className="contact-details-text"
                      />
                    </div>

                    <div>
                      <label className="label-txt">Linked in profile</label>
                      <br></br>
                      <input
                        disabled={currentStep.step === steps[4].step ? true : false}
                        type="url"
                        placeholder="url"
                        value={contestContactAndLegal.linkedInProfile}
                        name="linkedInProfile"
                        onChange={onContestContactAndLegaChange}
                        className="contact-details-text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Terms & Conditions</span>
                  <span className="contest-sub-text">
                    This section provides a comprehensive overview of the
                    official rules and guidelines governing the contest.
                  </span>
                </div>
                <div>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <div className="terms-show-check-link">
                      <span>
                        <input
                          disabled={currentStep.step === steps[4].step ? true : false}
                          type="checkbox"
                          id="termsAsCheckbox"
                          value="CHECKBOX"
                          checked={
                            contestContactAndLegal.termsOptionsDisplayType ===
                            "CHECKBOX"
                          }
                          onChange={handleTermsType}
                        />{" "}
                        <label className="checkbox-fee-text">
                          Show as a checkbox{" "}
                        </label>{" "}
                      </span>
                      <span>
                        <input
                          disabled={currentStep.step === steps[4].step ? true : false}
                          type="checkbox"
                          id="termsAsLink"
                          value="LINK"
                          checked={
                            contestContactAndLegal.termsOptionsDisplayType ===
                            "LINK"
                          }
                          onChange={handleTermsType}
                        />
                        <label
                          className="checkbox-fee-text"
                          style={{ lineHeight: 1 }}
                        >
                          Show as a link
                        </label>{" "}
                      </span>
                    </div>

                    <textarea
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="write your description here..."
                      value={contestContactAndLegal.termsAndConditions}
                      name="termsAndConditions"
                      style={{
                        resize: "none",
                        overflow: "scroll",
                        paddingRight: 60,
                      }}
                      onChange={onContestContactAndLegaChange}
                      className="entry-rules-box"
                      required
                    />
                    <div>
                      <span className="terms-and-conditions-input-length-count">
                        {contestContactAndLegal.termsAndConditions.length}/250
                      </span>
                    </div>
                    <div>
                      <span className="contest-box-sub-text">
                        You cannot edit this section after posting.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="grey-border" />

              <div className="contest-queries-box-row">
                <div className="contest-queries">
                  <span className="contest-basics">Privacy policy</span>
                  <span className="contest-sub-text">
                    State how you will handle the contestants personal
                    information.
                  </span>
                </div>
                <div>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <div className="terms-show-check-link">
                      <span>
                        <input
                          disabled={currentStep.step === steps[4].step ? true : false}
                          type="checkbox"
                          id="privacyAsChecbox"
                          name="selectedPrivacyPolicyType"
                          value="CHECKBOX"
                          checked={
                            contestContactAndLegal.privacyPolicyDisplayType ===
                            "CHECKBOX"
                          }
                          onChange={handlePrivacyPolicyType}
                        />{" "}
                        <label className="checkbox-fee-text">
                          Show as a checkbox{" "}
                        </label>{" "}
                      </span>
                      <span>
                        <input
                          disabled={currentStep.step === steps[4].step ? true : false}
                          type="checkbox"
                          id="privacyAsLink"
                          name="selectedPrivacyPolicyType"
                          value="LINK"
                          checked={
                            contestContactAndLegal.privacyPolicyDisplayType ===
                            "LINK"
                          }
                          onChange={handlePrivacyPolicyType}
                        />
                        <label className="checkbox-fee-text">
                          Show as a link
                        </label>{" "}
                      </span>
                    </div>
                    <textarea
                      disabled={currentStep.step === steps[4].step ? true : false}
                      placeholder="write your description here..."
                      value={contestContactAndLegal.privacyPolicy}
                      name="privacyPolicy"
                      style={{
                        resize: "none",
                        overflow: "scroll",
                        paddingRight: 60,
                      }}
                      onChange={onContestContactAndLegaChange}
                      className="entry-rules-box"
                      required
                    />
                    <div>
                      <span className="terms-and-conditions-input-length-count">
                        {contestContactAndLegal.privacyPolicy.length}/250
                      </span>
                    </div>
                    <div>
                      <span className="contest-box-sub-text">
                        You cannot edit this section after posting.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      )}

      <div>
        <StepperFooterButtons
          totalNoOfSteps={5}
          currentStep={currentStep}
          onClickCancel={cancelStep}
          onClickContinue={stepContinue}
        />
      </div>
      
      <div>
        {/* <StepperFooterButtons totalNoOfSteps={5} currentStep={step} onClickCancel={() => cancelStep(step)} onClickContinue={() => stepContinue(step)} /> */}
      </div>
    </div>
  );
};
export default ContestForm;
