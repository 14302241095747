import { Outlet } from "react-router-dom"
import Header from "../../shared/components/header"
import LeftNav from "../../shared/components/left-nav"
import { AuthProps, useAuth } from "../../hooks/providers/auth.provider"
import { ToastContainer } from "react-toastify"

const Main = () => {

    const userAuth:AuthProps | null  = useAuth()
      
    return <div className="sl-main-container">
        <ToastContainer />
        <Header user={userAuth?.user} />
        <div className="sl-body-container">
            {userAuth?.userProfile &&  <LeftNav logout={userAuth.logout} user={userAuth.user} userProfile={userAuth.userProfile} /> }
            <Outlet />
        </div>
    </div>
}

export default (Main)
