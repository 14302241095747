import { createAsyncThunk } from "@reduxjs/toolkit"
import { get, put } from "../../../utils/https";
import { PaginationQueryParams } from "../../../models/common.model";

export type StoryContestStatusEnum = 'SUBMIT' | 'PENDING' | 'SELECTED' | 'REJECTED'

export interface ContestStoryMapUpdateRequest {
   storyId: string
   contestId: string
   status: StoryContestStatusEnum
   comments: string
}

export const fetchContestList = createAsyncThunk('fetch/contest', async (pagination: PaginationQueryParams) => {
        try{
        const response = await get(`contest?page=${pagination.page}&itemsPerPage=${pagination.itemsPerPage}`);
        return response.data;
        }
        catch(err) {
        console.log(err)
        }
     }
);

export const fetchContesApplication = createAsyncThunk('fetch/contest/applications', async (contestId: string) => {
   try{
   const response = await get(`contest/stories?contestId=${contestId}`);
   console.log(response.data);
   return response.data;
   }
   catch(err) {
   console.log(err)
   }
});

export const submitContesApplicationStatus = createAsyncThunk('put/contest/story/status', async (paylod: ContestStoryMapUpdateRequest) => {
   try{
   const response = await put(`contest/story`, paylod);
   return response.data;
   }
   catch(err) {
   console.log(err)
   }
});
