import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios"
import { auth } from "../services/firebase.service"
import { UPLOAD_TYPES } from "../models/common.model"

export const ENDPOINT_URL = 'https://storyline.d5meta.com/api/'

// export const ENDPOINT_URL = 'http://localhost:8000/'

/**
 * 
 * TODO - Sai
 * Add Network retries on failure
 * Add global exception handelers
 * add Interceptors to pass user access token to backend to authneticate(HIGH PRIORITY)
 */

axios.interceptors.request.use(
    async config => {
      const token = await auth.currentUser?.getIdToken()
      if (token) {
        config.headers['X-Auth-Token'] = token
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

export const get = <T> (url: string, headers: RawAxiosRequestHeaders = {}): Promise<AxiosResponse<T>> => {
    url = ENDPOINT_URL + url

    return axios.get(url, {
        headers: {
            Accept: 'application/json', 
            ...headers
        }
    })
}

export const post = <R,T> (url: string, data: R,  headers: RawAxiosRequestHeaders = {}): Promise<AxiosResponse<T>> => {
    url = ENDPOINT_URL + url
    return axios.post(url, data, {
        headers: {
            Accept: 'application/json', 
            ...headers
        }
    })
}

export const put = <R,T> (url: string, data: R,  headers: RawAxiosRequestHeaders = {}): Promise<AxiosResponse<T>> => {
  url = ENDPOINT_URL + url
  return axios.put(url, data, {
      headers: {
          Accept: 'application/json', 
          ...headers
      }
  })
}

export const upload = (file: File, fileType: UPLOAD_TYPES, id?: string ) => {
  const url = ENDPOINT_URL + 'config/file-upload'
  const formData = new FormData();
  formData.append('file',file)

  return  post(url, formData, {
    "Content-Type": 'multipart/form-data'
  })
}