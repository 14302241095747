import React from 'react'
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import { FiGlobe } from 'react-icons/fi'
import LOGO from '../../../images/logo.png'
import '../about-us/about-us.css'
import RowColLayoutExample from './about-content';

const logoImage = require('../../../images/logo-no-bg.png')

export interface HeaderProps {
    user?: any
    onLoginPress?: VoidFunction
    onTryForFreePress?: VoidFunction
}
export const About = (props: HeaderProps) => {
    const user = undefined
    return (
        <>
           <Navbar expand="lg" className="header d-flex flex-row justify-content-between">
                <Navbar.Brand className='app-name primary-txt-color' href="#home">
                    <img src={logoImage} className='logo-img' alt='Storyline' />Storyline</Navbar.Brand>
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='flex-grow-0'>
                <Nav className='flex-grow-0'>
                    <Nav.Link href="/about" className='text-end primary-txt-color'>About</Nav.Link>
                    <Nav.Link href="/privacy" className='text-end primary-txt-color'>Privacy</Nav.Link>
                    <Nav.Link  href="/help" className='text-end primary-txt-color'>Help</Nav.Link>
                    <Nav.Item>
                        <Button variant='link' className='login-link' onClick={props.onLoginPress}>Login</Button>
                    </Nav.Item>
                    <Nav.Item>
                       <Button className='try-btn' onClick={props.onLoginPress}>Try for Free</Button>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
    </Navbar>
   <RowColLayoutExample/>
        </>
    )
}

export default About;